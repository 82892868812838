import React, { useState } from 'react';
import { useLang } from 'contexts/lang';
import MyRides from './myRides';
import { MyRidesProvider } from 'contexts/myRides';
import 'style/mobileView.scss';
import NavigationBar from 'components/navigationBar';
import { faCar, faCars } from '@fortawesome/pro-regular-svg-icons';
import { ClubRidesProvider } from 'contexts/clubRides';
import ClubRides from './clubRides';

function Rides() {
    const { dict } = useLang();
    const [view, setView] = useState('clubrides');

    const navButtons = [
        {id: 'clubrides', icon: faCars, label: 'Club Rides'},
        {id: 'myrides', icon: faCar, label: 'My Rides'},
    ];

    return (
        <div className='mobile-view within-portal'>
            <div className='view-container'>
                {view === 'myrides' && <MyRidesProvider><MyRides /></MyRidesProvider>}
                {view === 'clubrides' && <ClubRidesProvider limit={10}><ClubRides /></ClubRidesProvider>}
            </div>
            <NavigationBar buttons={navButtons} selected={view} onSetView={setView} />
        </div>
    )
}

export default Rides;