import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';
import { useAuth } from './auth';
import moment from 'moment';
import { useToken } from './token';

const SettingsContext = React.createContext(null);

const DEFAULTSETTINGS = {
    id: null,
    additionalEmails: '',
    autoRenew: false,
    notifyNewFile: true,
    notifyNewEvent: true,
    notifyNewProduct: true
}

function SettingsProvider({children}) {
    const { token } = useToken();
    const { runAction } = useCore();
    const { tenant } = useSession();
    const { member, authorize } = useAuth();
    const [settings, setSettings] = useState(DEFAULTSETTINGS);
    const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(true);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        if (token) {
            getSettings();
        }
    }, [token]);

    const getSettings = async () => {
        await runAction('get_member_settings', {}, (response) => {
            if (response.success) {
                setSettings(response.settings);
            }
        });
    }

    const saveSettings = async (data, callback) => {
        await runAction('update_member_settings', data, (response) => {
            if (response.success) {
                getSettings();
            }
            callback(response);
        });
    }

    const updateMember = async (data, callback) => {
        await runAction('update_member', data, (response) => {
            if (response.success) {
                authorize();
            }
            callback(response);
        });
    }

    const setPassword = async (password, callback) => {
		const data = {
				"tenant": tenant.id,
				"username": member.email_address,
				"password": password
			};
        await runAction('member_set_password', data, (response) => {
            if (response.success) {
                callback();
            }
        });
    }

    const add1Year = async (callback) => {
        await runAction('stripe_membership_renew', {}, (response) => {
            if (response.success) {
                alert('Your membership has been renewed and your new expiration date is ' 
                    + moment(response.expiration).format('YYYY-MM-DD') + '. Refresh this page to reload your Payment History.');
                callback(response.expiration);
            } else {
                throw new Error(response.errorMessage);
            }
        });
    }

    const getPaymentHistory = async () => {
        let data = {'memberId': member.id};
        setPaymentHistoryLoading(true);
        await runAction('stripe_get_payment_history', data, (response) => {
            if (response.success) {
                setPaymentHistory(response.paymenthistory);
            } else {
                throw new Error(response.errorMessage);
            }
            setPaymentHistoryLoading(false);
        });
    }

    const getPaymentMethods = async () => {
        let data = {'memberId': member.id};
        setPaymentMethodsLoading(true);
        await runAction('stripe_get_payment_methods', data, (response) => {
            if (response.success) {
                setPaymentMethods(response.paymentmethods);
            } else {
                throw new Error(response.errorMessage);
            }
            setPaymentMethodsLoading(false);
        });
    }

    const makePaymentMethodDefault = async (paymentMethodId) => {
        let data = {'memberId': member.id, 'paymentMethodId': paymentMethodId};
        await runAction('stripe_attach_payment_method', data, (response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    }

    const removePaymentMethod = async (paymentMethodId) => {
        let data = {'memberId': member.id, 'paymentMethodId': paymentMethodId};
        await runAction('stripe_detach_payment_method', data, (response) => {
            if (response.success) {
                getPaymentMethods();
            }
        });
    }

    const getClientSecret = async (callback) => {
        let data = {'save': true, 'memberId': member.id}
        await runAction('create_payment_intent', data, (response) => {
            if (response.success) {
                callback(response.clientSecret);
            }
        });
    }

    const provider = {
        settings,
        saveSettings,
        updateMember,
        setPassword,
        add1Year,
        paymentHistoryLoading,
        paymentHistory, getPaymentHistory,
        paymentMethodsLoading,
        paymentMethods, getPaymentMethods,
        makePaymentMethodDefault, removePaymentMethod,
        getClientSecret
    }

    return <SettingsContext.Provider value={provider}>{children}</SettingsContext.Provider>
}

function useSettings() {
    const context = React.useContext(SettingsContext);
    if (!context) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
}

export { SettingsProvider, useSettings }