import ProgressIndicator from 'components/progressIndicator';
import { useAuth } from 'contexts/auth';
import { EventCompletionProvider } from 'contexts/eventCompletion';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { ShiftsProvider } from 'contexts/purchasing/shifts';
import { CompleteEvent } from 'purchasing/complete';
import WorkerInfo from 'purchasing/workerInfo';
import ShiftPicker from 'purchasing/shiftPicker';
import React, { useEffect, useState } from 'react';
import VolunteerSummary from './volunteerSummary';
import { toSpliced } from '_base';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';

const volunteerSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'register', label: 'Select Shifts', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function EventVolunteer({event}) {
    const { setDescription,  selectedShifts, completed } = usePurchaseOrder();
    const { member } = useAuth();
    const { setMemberId, isWiComplete } = useWorkerInfo();
    const [steps, setSteps] = useState(volunteerSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (event) {
            setDescription(event.name + ' tickets');
        }
    }, [event])

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
        }
    }, [member])

    useEffect(() => {
        if (isWiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isWiComplete])

    useEffect(() => {
        if (selectedShifts.length > 0) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [selectedShifts])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <WorkerInfo event={event} />}
                {currentStep === 1 &&
                    <ShiftsProvider eventId={event.id}>
                        <ShiftPicker />
                    </ShiftsProvider>
                }
                {currentStep === 2 &&
                    <EventCompletionProvider>
                        <CompleteEvent eventId={event.id} />
                    </EventCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <VolunteerSummary />
        </div>
    )
}

export default EventVolunteer;