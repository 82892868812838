import ProgressIndicator from 'components/progressIndicator';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useSession } from 'contexts/session';
import PersonalInfo from 'purchasing/personalInfo';
import React, { useEffect, useState } from 'react';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import Payment from 'purchasing/payment';
import { MembershipCompletionProvider } from 'contexts/membershipCompletion';
import { CompleteDonation } from 'purchasing/complete';
import DonationAmount from 'purchasing/donationAmount';
import Summary from './summary';
import { toSpliced } from '_base';
import StandardAgreement from 'purchasing/standardAgreement';

const donationSteps = [
    {id: 'amount', label: 'Donation Amount', complete: false, state: 'active'},
    {id: 'personal', label: 'Personal Info', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function DonationForm() {
    const { tenant } = useSession();
    const { setDescription, description, donation, agreement, setAgreement, paid, completed } = usePurchaseOrder();
    const { isPiComplete, email } = usePersonalInfo();
    const [steps, setSteps] = useState(donationSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        setDescription(tenant.name + ' donation');
    }, [])

    useEffect(() => {
        if (donation > 0) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [donation])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
        } else {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
        }
    }, [completed])


    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <DonationAmount />}
                {currentStep === 1 && <PersonalInfo />}
                {currentStep === 2 && <StandardAgreement description={description} />}
                {currentStep === 3 &&
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                { currentStep === 4 &&
                    <MembershipCompletionProvider>
                        <CompleteDonation />
                    </MembershipCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <Summary />
        </div>
    )
}

export default DonationForm;