import React from 'react';
import { useLang } from 'contexts/lang';
import {formatPhoneNumber} from '_base';
import { useRoster } from 'contexts/roster';
import Avatar from 'components/avatar';

function RosterTab() {
    const { dict } = useLang();
    const { membersLoading, members } = useRoster();

    return (
        <div>
            {membersLoading && <div>Loading...</div>}
            {members &&
                <div className='roster'>
                    <table >
                        <thead>
                            <tr><th></th><th>{dict.name}</th><th>{dict.partner}</th><th>{dict.email}</th><th>{dict.phoneNumber}</th><th>{dict.city}</th></tr>
                        </thead>
                        <tbody>
                            {members.map((m) => 
                                <tr key={m.id}>
                                    <td><Avatar value={m?.avatar} width='50' height='50' name={m.name} /></td>
                                    <td>{m.name}</td>
                                    <td>{m.partner}</td>
                                    <td>{m.email}</td>
                                    <td>{formatPhoneNumber(m.phone)}</td>
                                    <td>{m.locale}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default RosterTab;