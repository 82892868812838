import React from 'react';
import { Outlet } from 'react-router-dom';
import AppTabs from 'components/appTabs';
import AppOptions from 'components/appOptions';
import { useSession } from 'contexts/session';

function Public() {
    const { tenant } = useSession();

    return (
        <>
            <div className='body-content'>
                <div className='left-side'>
                    {tenant?.customerType === 0 &&
                        <>
                            <AppTabs />
                            <AppOptions />
                        </>
                    }
                    <div className='tab-content'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Public;