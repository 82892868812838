import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useAuth } from './auth';

const MyRidesContext = React.createContext(null);

function MyRidesProvider({children}) {
    const { runAction } = useCore();
    const { authLoading, member } = useAuth();
    const [ridesLoading, setRidesLoading] = useState(true);
    const [rides, setRides] = useState([]);

    useEffect(() => {
        if (!authLoading && member) {
            loadRides();
        }
    }, [authLoading])

    const loadRides = async() => {
        setRidesLoading(true);
        await runAction('get_member_rides', {memberId: member.id}, response => {
            setRides(response.rides);
            setRidesLoading(false);
        })
    }

    const createRide = async(data) => {
        await runAction('create_member_ride', {...data, memberId:member.id}, response => {
            console.log('created new ride ', response.id);
            loadRides();
        })
    }

    const updateRide = async(data) => {
        await runAction('update_member_ride', data, response => {
            console.log('updated ', response.updated, ' ride(s)');
            loadRides();
        })
    }

    const deleteRide = async (rideId) => {
        await runAction('delete_member_ride', {id: rideId}, response => {
            console.log('deleted ', response.deleted, ' ride' );
            loadRides();
        })
    }

    const provider = {
        ridesLoading,
        rides,
        createRide,
        updateRide,
        deleteRide,
    }

    return <MyRidesContext.Provider value={provider}>{children}</MyRidesContext.Provider>
}

function useMyRides() {
    const context = React.useContext(MyRidesContext);
    if (!context) {
        throw new Error('useMemberRides must be used within a MemberRidesProvider');
    }
    return context;
}

export { MyRidesProvider, useMyRides }