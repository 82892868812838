import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from './purchaseOrder';

const VendorInfoContext = React.createContext(null);

const PIKEY = 'vendorInfo';

function VendorInfoProvider({children}) {
    const { completed } = usePurchaseOrder();
    const [isLoading, setIsLoading] = useState(true);
    const [memberId, setMemberId] = useState(null);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [allowEmailMarketing, setAllowEmailMarketing] = useState(true);
    const [vendorType, setVendorType] = useState(0);
    const [taxId, setTaxId] = useState('');
    const [description, setDescription] = useState('');
    const [isPiComplete, setIsPiComplete] = useState(false);

    useEffect(() => {
        setIsPiComplete(name?.length > 0 
            && address?.length > 0 
            && city?.length > 0
            && state?.length > 0 
            && zip?.length > 0
            && phone?.length > 0 && email?.length > 0);
    }, [name, address, city, state, zip, phone, email])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const piJSONString = JSON.stringify({memberId, name, address, city, state, zip, phone, email, allowEmailMarketing, vendorType, taxId, description});
            window.sessionStorage.setItem(PIKEY, piJSONString);
        }
    }, [memberId, name, address, city, state, zip, phone, email, allowEmailMarketing, vendorType, taxId, description])

    useEffect(() => {
        if (window.sessionStorage) {
            const piJSONString = window.sessionStorage.getItem(PIKEY);
            if (piJSONString) {
                try {
                    const piJSON = JSON.parse(piJSONString);
                    setMemberId(piJSON.memberId);
                    setName(piJSON.name);
                    setAddress(piJSON.address);
                    setCity(piJSON.city);
                    setState(piJSON.state);
                    setZip(piJSON.zip);
                    setPhone(piJSON.phone);
                    setEmail(piJSON.email);
                    setAllowEmailMarketing(piJSON.allowEmailMarketing);
                    setVendorType(piJSON.vendorType);
                    setTaxId(piJSON.taxId);
                    setDescription(piJSON.description);
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(PIKEY);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(PIKEY);
        }
    }, [completed])

    const resetVi = () => {
        window.sessionStorage.removeItem(PIKEY);
    }

    const provider = {
        memberId, setMemberId,
        name, setName,
        address, setAddress,
        city, setCity,
        state, setState,
        zip, setZip,
        phone, setPhone,
        email, setEmail,
        allowEmailMarketing, setAllowEmailMarketing,
        vendorType, setVendorType,
        taxId, setTaxId,
        description, setDescription,
        isPiComplete,
        resetVi
    }

    return <VendorInfoContext.Provider value={provider}>{children}</VendorInfoContext.Provider>
}

function useVendorInfo() {
    const context = React.useContext(VendorInfoContext);
    if (!context) {
        throw new Error('useVendorInfo must be used within a VendorInfoProvider');
    }
    return context;
}

export { VendorInfoProvider, useVendorInfo }