import React from 'react';
import moment from 'moment';
import { useLang } from 'contexts/lang';
import { useActivities } from 'contexts/activities';
import MonthPicker from 'components/monthPicker';

function Activities() {
    const { dict } = useLang();
    const { activities, startDate, setStartDate, endDate, setEndDate } = useActivities();

    let monthLabel = '';

    const changeStartMonthHandler = (month, year) => {
        const newStart = moment().year(year).month(month).date(1).hour(0).minute(0).second(0).millisecond(0);
        setStartDate(newStart.toDate().getTime());
    }

    const changeEndMonthHandler = (month, year) => {
        const lastDayOfMonth = moment().year(year).month(month).daysInMonth();
        const newEnd = moment().year(year).month(month).date(lastDayOfMonth).hour(23).minute(59).second(59).millisecond(999);
        setEndDate(newEnd.valueOf());
    }

    return(
        <div className='events-container'>
            <div className='events-label'>{dict.activities}</div>
            <div className='events-content' style={{border:'none',margin:'10px'}}>
                {startDate && endDate &&
                    <div className='acitvities-dates'>
                        <label>Start date</label>
                        <MonthPicker value={startDate} onChange={changeStartMonthHandler} />
                        <label>End date</label>
                        <MonthPicker value={endDate} onChange={changeEndMonthHandler} />
                    </div>
                }
                {
                    activities.length > 0 
                    ? activities.map((e) => {
                        const activityMonthLabel = moment(e.date).format('MMMM YYYY');
                        let showMonthLabel = false;
                        if (monthLabel !== activityMonthLabel) {
                            monthLabel = activityMonthLabel;
                            showMonthLabel = true;
                        }
                        let start = e.start.split(':');
                        let end = e.end.split(':');

                        return (
                        <div key={e.id} className='activity'>
                            {showMonthLabel && <h1>{monthLabel}</h1>}
                            <div className='day'>{moment(e.date).format('dddd Do')}</div>
                            <div className='time'>({moment(e.date).hour(start[0]).minute(start[1]).format('h:mm a')} - 
                            {moment(e.date).hour(end[0]).minute(end[1]).format('h:mm a')})</div>
                            <h3 className='title'>{e.title}</h3>
                            <div className='description'>{e.description}</div>
                        </div>)
                    }) 
                    : <div style={{height:'400px',display:'flex',justifyContent:'center',alignItems:'center'}}>{dict.noActivities}</div>
                }
            </div>
        </div>
    )
}

export default Activities;