import React, { useEffect } from 'react';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';

function PersonalInfo({event}) {
    const { dict } = useLang();
    const { member} = useAuth()

    const {fname, setFname, 
        lname, setLname, 
        phone, setPhone,
        email, setEmail,
        allowEmailMarketing, setAllowEmailMarketing,
        swag, setSwag} = useWorkerInfo();
    

    useEffect(() => {
        if (member) {
            setFname(member.fname);
            setLname(member.lname);
            setEmail(member.email_address);
        }
    }, [member])

	/**
	 * render 
	 */
    return (
        <div className='personal-info'>
            <p>{dict.allFieldsRequired}</p>
            <label>{dict.firstName}</label>
            <input type='text' aria-required='true' value={fname} data-testid='fname' maxLength='24' onChange={(evt) => setFname(evt.target.value)} />
            <label>{dict.lastName}</label>
            <input type='text' aria-required='true' value={lname} data-testid='lname' maxLength='24' onChange={(evt) => setLname(evt.target.value)} />
            <label>{dict.phoneNumbersOnly}</label>
            <input type='phone' aria-required='true' value={phone} data-testid='phone' maxLength='12' onChange={(evt) => setPhone(evt.target.value)} />
            <label>{dict.emailAddress}</label>
            <input type='email' aria-required='true' value={email} data-testid='email' maxLength='48' onChange={(evt) => setEmail(evt.target.value)} />
            <div>
                <input type='checkbox' checked={allowEmailMarketing} onChange={evt => setAllowEmailMarketing(evt.target.checked)} />
                <label>Opt-in to recieving marketing emails about this event</label>
            </div>
            {event?.workerChoices && event.workerChoices?.length > 0 &&
                <>
                    <label>Preferred thank you gift (while supplies last)</label>
                    <select value={swag} onChange={(evt) => setSwag(evt.target.value)}>
                        <option value='-'>None</option>
                        {event.workerChoices.split(',').map((choice, idx) => <option key={idx} value={choice}>{choice}</option>)}
                    </select>
                </>
            }
        </div>
    )
}

export default PersonalInfo;