import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import React from 'react';

function Agreement({event}) {
    const { agreement, setAgreement } = usePurchaseOrder();

    return (
        <>
            <div className='agreement-container'>
                <h1>Middletwin agreement</h1>
                <p>
                    In order to facilitate this purchase you will be required to use a credit card in the next step.
                    This purchase will appear on your banks statement with the description {`"${event.name}"`} and show that
                    that the purchase was from a company called Middletwin.
                </p>
                <p>
                    By checking the box below you agree that you will not dispute this
                    charge with your bank, and that if your bank disputes the charge on your behalf that you will take 
                    every reasonable action with the bank to get the bank to drop the disputed charge.
                </p>
                <p>
                    You also agree with the Middletwin <a href='https://www.middletwin.com/terms-of-use/' target='_blank'>Terms of use</a>.
                </p>
                {event.agreement && event.agreement.length > 0 &&
                    <>
                        <h1>{`${event.name} agreement`}</h1>
                        <p>{event.agreement}</p>
                    </>
                }
            </div>
            <div>
                <input type='checkbox' checked={agreement} onChange={evt => setAgreement(evt.target.checked)} />
                By checking this box you are agreeing to the terms above.
            </div>
        </>
    )
}

export default Agreement;