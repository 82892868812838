import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const RosterContext = React.createContext(null);

function RosterProvider({children}) {
    const { runAction } = useCore();
    const [membersLoading, setMembersLoading] = useState(true);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        getMembers();
    }, [])

    const getMembers = async () => {
        setMembersLoading(true);
        let data = {'nostats': true, 'type': 'roster'};
        runAction('get_members_for_roster', data, (response) => {
            setMembersLoading(false);
            setMembers(response.members);
        });
    }

    const provider = {
        membersLoading,
        members,
    }

    return <RosterContext.Provider value={provider}>{children}</RosterContext.Provider>
}

function useRoster() {
    const context = React.useContext(RosterContext);
    if (!context) {
        throw new Error('useRoster must be used within a RosterProvider');
    }
    return context;
}

export { RosterProvider, useRoster }