import { formatCurrency, toSpliced } from '_base';
import Loading from 'components/loading';
import { useEvent } from 'contexts/event';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

function SpacePicker({eventId}) {
    const { eventLoading, maps } = useEvent();
    const { spaces, setSpaces } = usePurchaseOrder();
    const [availableMaps, setAvailableMaps] = useState([]);
    const [selectedMap, setSelectedMap] = useState(0);
    const eventMap = React.createRef(null);

    useEffect(() => {
        if (maps) {
            const _maps = maps.filter(map => map.available && moment().isAfter(map.availableStartdate) && moment().isBefore(map.availableEnddate));
            setAvailableMaps(_maps);
        }
    }, [maps])

    const selectElement = (event, element) => {
        if (isReserved(element.id) || !element.available) {
            return;
        }
        console.log(element);
        if (isSelected(element.id)) {
            removeSpaceHandler(element.id);
        } else {
            const eventMapId = availableMaps[selectedMap].id;
            const memberDiscount = availableMaps[selectedMap].memberDiscount;
            setSpaces([...spaces, {
                event: eventId, 
                eventMapId,
                elem: element.id, 
                type: element.type, 
                cost: element.cost,
                memberDiscount,
                location: (element.row+element.num), 
                notes: element.notes
            }])
        }
    }
    const removeSpaceHandler = (spaceId) => {
        const spaceIndex = spaces.findIndex(r => r.elem === spaceId);
        if (spaceIndex > -1) {
            let newArray = [...spaces];
            newArray.splice(spaceIndex, 1);
            setSpaces(newArray);
        }
    }
    const isSelected = (spaceId) => {
        return spaces.findIndex(space => space.elem === spaceId) > -1;
    }
    const isReserved = (spaceId) => {
        return availableMaps[selectedMap].reservations?.findIndex(resy => resy.eventMapElementId === spaceId) > -1;
    }

    return (
        <div>
            <div className='legend'>
                <div className='key'></div><div className='label'>Available</div>
                <div className='key unavailable'></div><div className='label'>Unavailable</div>
                <div className='key selected'></div><div className='label'>Selected</div>
            </div>
            {eventLoading ? <Loading /> : availableMaps.length > 0 ?
                <>
                    <label>Current map:</label>
                    <select value={selectedMap} onChange={evt => setSelectedMap(evt.target.value)}>
                        {availableMaps.map((map, index) => <option key={map.id} value={index}>{map.label}</option>)}
                    </select>
                    <div className='mapContainer' style={{
                            backgroundImage:`url(${availableMaps[selectedMap].backgroundImage})`,
                            backgroundSize:'contain',
                            backgroundRepeat:'no-repeat',
                            margin:'5px 0'
                        }}>
                        <svg ref={eventMap} version='1.1' viewBox='0,0,1024,768' tabIndex='0'
                            xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                                {availableMaps[selectedMap].elements.map((element, idx) => {
                                    let elem = null;
                                    switch(element.shape) {
                                        case 'box': 
                                            elem = <path id={`p-${idx}`} key={idx} stroke={isSelected(element.id) ? '#F00' : '#000'}
                                                fill={isReserved(element.id) || !element.available ? '#CCC' : (isSelected(element.id) ? '#FAA' : '#FFF')}
                                                d={`M${element.x},${element.y} L${(element.x+element.w)},${element.y} L${(element.x+element.w)},${(element.y+element.h)} L${element.x},${(element.y+element.h)} Z`}
                                                transform={!isNaN(element.a) ? `rotate(${element.a},${element.x},${element.y})` : null} 
                                                onClick={(evt) => selectElement(evt, element)} />
                                            break;
                                        case 'circle': 
                                            elem = <circle key={idx} stroke='#000' fill={isReserved(element.id) || !element.available ? '#CCC' : '#FFF'}
                                            cx={element.x} cy={element.y} r={element.w} 
                                            onClick={(evt) => selectElement(evt, element)} />
                                            break;
                                        default: break;
                                    }
                                    return elem;
                                })}
                                {availableMaps[selectedMap].elements.map((element, idx) => {
                                        let elem = null;
                                        if (element.shape === 'box' && element.row.length > 0) {
                                            elem = <text style={{fontSize:'14px'}} dy={14} 
                                                    onClick={(evt) => selectElement(evt, element)}>
                                                <textPath xlinkHref={`#p-${idx}`} startOffset='2%' >{`${element.row}${element.num}`}</textPath></text>
                                        }
                                        return elem
                                    })}
                        </svg>
                    </div>
                </>
                :
                <div>We're sorry, there are no available spaces to purchase at this time. Please try again later or contact the event staff for assistance.</div>
            }
            <div className='spacesList'>
                <ul>
                {
                    spaces.map(s => 
                        <li>
                            <div>Space {s.location}:</div>
                            <div>{`Type: ${s.type === 1 ? 'Swap' : s.type === 2 ? 'Food' : s.type === 3 ? 'Merch' : 'Any'} ${s.notes}`}</div>
                            <div>{formatCurrency(s.cost)}<button className='linkButton' onClick={() => removeSpaceHandler(s.elem)}>Remove</button></div>
                        </li>)
                }
                </ul>
            </div>
        </div>
    )
}

export default SpacePicker;