import React, { useEffect, useState } from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useSocial } from 'contexts/social';
import HiddenCanvas from 'components/hiddenCanvas';

function CreatePost(props) {
    const { member } = useAuth();
    const { dict } = useLang();
    const { topics, createTopic, savePost } = useSocial();
    const [message, setMessage] = useState('');
    const [images, setImages] = useState([]);
    const [topicId, setTopicId] = useState(topics[0].id);

    useEffect(() => {
        if (props.post && !props.isComment) {
            setMessage(props.post.post.message);
            setImages(props.post.media);
        }
    }, [props.post]);
    
    const uploadMediaHandler = (imageData) => {
        setImages(images.concat({imageData}));
    }
    const deleteMediaHandler = (imageIdx) => {
        let newImgArray = images.splice(imageIdx, 1);
        setImages(newImgArray);
    }
    const savePostHandler = () => {
        let data = {
            'memberId': member.id, 
            'topicId': topicId,
            'message': message, 
            'images': images};
        if (props.post && !props.isComment) {
            data["id"] = props.post.post.id;
        }
        if (props.isComment) {
            data["parentId"] = props.post.post.id;
        }
        savePost(data, () => props.onDone());
    }
    
    const newTopicHandler = () => {
        const newTopic = prompt('Create new topic (64 characters max)');
        setTopicId(createTopic(newTopic));
    }

    return(
        <div className='create-classified'>
            <div className='back-button-container'>
                <div onClick={props.onDone}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToPosts}</div>
            </div>
            <fieldset>
                <legend>Topic</legend>
                <span>Topic for this post: </span>
                <select value={topicId} onChange={evt => setTopicId(evt.target.value)}>
                    {topics.map(t => <option value={t.id}>{t.topic}</option>)}
                </select>
                <button className='linkButton' onClick={newTopicHandler}>Create new topic</button>
            </fieldset>
            <div className='instructions-area'>
                <div>{dict.postInstructions}</div>
            </div>
            <div className='classified-media'>
                <label>Images</label>
                <HiddenCanvas buttonTitle='Add image' width={400} height={400} onChange={uploadMediaHandler}/>
                <div className='image-viewer'>
                    {images.map((image, idx) => 
                        <div key={idx} className='image-view-container'>
                            <div className='image-view-image' style={{backgroundImage:`url(${image.imageData})`}} />
                            <div className='image-view-buttons'>
                                <button className='linkButton' onClick={() => deleteMediaHandler(idx)}>Remove</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='classified-details'>
                <label>{dict.postMessage}</label>
                <textarea name='message' value={message} onChange={e => setMessage(e.target.value)} maxLength='1024' />
            </div>
            <div className='classified-save-button'>
                <button style={getButtonStyle()} onClick={savePostHandler}>{dict.save}</button>
            </div>
            
        </div>
    )
}

export default CreatePost;