import React from 'react';
import { formatCurrency } from '_base';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';

function TicketSummary() {
    const { 
        serviceFee, 
        taxes, 
        ticketTotal,
        grandTotal,
        completed,
        resetPO
    } = usePurchaseOrder();
    const { resetPi } = usePersonalInfo();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const cancelHandler = () => {
        resetPO();
        resetPi();
        navigate('/portal/member/events');
    }

    const finishHandler = () => {
        const redirectUrl = searchParams.get('redirect');
        if (redirectUrl) {
            window.location.href = redirectUrl
        } else {
            navigate('/portal/member/events');
        }
    }

    return (
        <div className='registration-summary'>
            <div className='registration-summary-title'>Order Summary</div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Tickets</div>
                <div className='item-amount'>{formatCurrency(ticketTotal)}</div>
            </div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Sub-total</div>
                <div className='item-amount'>{formatCurrency(ticketTotal)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Service fee</div>
                <div className='item-amount'>{formatCurrency(serviceFee)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Taxes</div>
                <div className='item-amount'>{formatCurrency(taxes)}</div>
            </div>
            <div className='registration-summary-total'>
                <div className='item-title'>Total</div>
                <div className='item-amount'>{formatCurrency(grandTotal)}</div>
            </div>
            <div className='registration-summary-buttons'>
                <button className='secondary' onClick={cancelHandler}>Cancel</button>
                <button disabled={!completed} onClick={finishHandler}>Finished</button>
            </div>
        </div>
    )
}

export default TicketSummary;