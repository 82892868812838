import { faArrowLeft, faArrowRight, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import moment from 'moment';

function MonthPicker({value, onChange}) {
    const [year, setYear] = useState(2025);
    const [month, setMonth] = useState(0);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (value) {
            const theValue = moment(value);
            setYear(theValue.year());
            setMonth(theValue.month());
        }
    }, [value])

    const chooseMonthHandler = (monthNum) => {
        setShowPopup(false);
        onChange(monthNum, year);
    }

    const isSelected = (monthNum) => {
        const isYear = moment(value).year() === year;
        return isYear && month === monthNum ? 'selected' : '';
    }

    return (
        <div className='month-picker'>
            <div role='button' className='current-value' onClick={() => setShowPopup(!showPopup)}>{`${month+1}/${year}`}</div>
            <div className={`month-picker-popup ${showPopup ? 'show' : ''}`}>
                <div className='year-chooser-container'>
                    <div className='year-chooser-controls'>
                        <div role='button' className='year-chooser-button' onClick={() => setYear(year-1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /></div>
                        <div className='year-chooser-label'>{year}</div>
                        <div role='button' className='year-chooser-button' onClick={() => setYear(year+1)}>
                            <FontAwesomeIcon icon={faArrowRight}/></div>
                    </div>
                    <div role='button' className='close-popup-button' onClick={() => setShowPopup(false)}><FontAwesomeIcon icon={faTimesCircle}/></div>
                </div>
                <div className='month-chooser-container'>
                    <div className='month-chooser-row'>
                        <div role='button' className={`month-choice ${isSelected(0)}`} onClick={() => chooseMonthHandler(0)}>Jan</div>
                        <div role='button' className={`month-choice ${isSelected(1)}`} onClick={() => chooseMonthHandler(1)}>Feb</div>
                        <div role='button' className={`month-choice ${isSelected(2)}`} onClick={() => chooseMonthHandler(2)}>Mar</div>
                        <div role='button' className={`month-choice ${isSelected(3)}`} onClick={() => chooseMonthHandler(3)}>Apr</div>
                    </div>
                    <div className='month-chooser-row'>
                        <div role='button' className={`month-choice ${isSelected(4)}`} onClick={() => chooseMonthHandler(4)}>May</div>
                        <div role='button' className={`month-choice ${isSelected(5)}`} onClick={() => chooseMonthHandler(5)}>Jun</div>
                        <div role='button' className={`month-choice ${isSelected(6)}`} onClick={() => chooseMonthHandler(6)}>Jul</div>
                        <div role='button' className={`month-choice ${isSelected(7)}`} onClick={() => chooseMonthHandler(7)}>Aug</div>
                    </div>
                    <div className='month-chooser-row'>
                        <div role='button' className={`month-choice ${isSelected(8)}`} onClick={() => chooseMonthHandler(8)}>Sep</div>
                        <div role='button' className={`month-choice ${isSelected(9)}`} onClick={() => chooseMonthHandler(9)}>Oct</div>
                        <div role='button' className={`month-choice ${isSelected(10)}`} onClick={() => chooseMonthHandler(10)}>Nov</div>
                        <div role='button' className={`month-choice ${isSelected(11)}`} onClick={() => chooseMonthHandler(11)}>Dec</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonthPicker;