import ProgressIndicator from 'components/progressIndicator';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useSession } from 'contexts/session';
import MemberInfo from './memberInfo';
import React, { useEffect, useState } from 'react';
import JoinSummary from './joinSummary';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import Payment from 'purchasing/payment';
import { MembershipCompletionProvider } from 'contexts/membershipCompletion';
import { CompleteMembership } from 'purchasing/complete';
import { useMembershipInfo } from 'contexts/purchasing/membershipInfo';
import { toSpliced } from '_base';
import StandardAgreement from 'purchasing/standardAgreement';

const membershipSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function MembershipJoin({membershipType}) {
    const { tenant } = useSession();
    const { setDescription, description, setMembership, agreement, paid, completed, grandTotal } = usePurchaseOrder();
    const { isPiComplete, email } = usePersonalInfo();
    const { setType } = useMembershipInfo();
    const [steps, setSteps] = useState(membershipSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (membershipType) {
            setDescription(tenant.name + ' membership');
            setMembership(membershipType.price);
            setType(membershipType.id);
        }
    }, [membershipType])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (agreement) {
            if (grandTotal > 0) {
                setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
            } else {
                // if testing and you want to skip past payment, comment the line above and uncomment the line below - Wade
                let _steps = [...steps];
                _steps[1].complete = true;
                _steps[2].complete = true;
                setSteps(_steps);
                setCurrentStep(currentStep + 2);
            }
        } else {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
        }
    }, [completed])


    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <div style={{textAlign:'center',fontWeight:'600',fontSize:'24px'}}>DO NOT USE THE BROWSER BACK BUTTON</div>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <MemberInfo />}
                {currentStep === 1 && <StandardAgreement description={description} />}
                {currentStep === 2 &&
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 3 &&
                    <MembershipCompletionProvider>
                        <CompleteMembership />
                    </MembershipCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <JoinSummary />
        </div>
    )
}

export default MembershipJoin;