import React, { useEffect, useState } from 'react';
import { useMemberTypes } from 'contexts/memberTypes';
import MemberLookup from './memberLookup';
import MembershipInfo from './membershipInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import ProgressIndicator from 'components/progressIndicator';
import RenewSummary from './renewSummary';
import { MembershipInfoProvider, useMembershipInfo } from 'contexts/purchasing/membershipInfo';
import { PersonalInfoProvider, usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { PurchaseOrderProvider } from 'contexts/purchasing/purchaseOrder';
import { MemberLookupProvider, useMemberLookup } from 'contexts/memberLookup';
import { toSpliced } from '_base';
import { useSession } from 'contexts/session';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import Payment from 'purchasing/payment';
import { MembershipCompletionProvider } from 'contexts/membershipCompletion';
import { CompleteMembershipRenewal } from 'purchasing/complete';
import { useAuth } from 'contexts/auth';
import StandardAgreement from 'purchasing/standardAgreement';

const membershipSteps = [
    {id: 'member', label: 'Member lookup', complete: false, state: 'active'},
    {id: 'membership', label: 'Membership Info', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function Renew() {

    return (
        <div className='events-container'>
            <PurchaseOrderProvider type='renewPO'>
                <PersonalInfoProvider>
                    <MembershipInfoProvider>
                        <MemberLookupProvider>
                            <MemberRenew />
                        </MemberLookupProvider>
                    </MembershipInfoProvider>
                </PersonalInfoProvider>
            </PurchaseOrderProvider>
        </div>
    )
}

function MemberRenew() {
    const { tenant } = useSession();
    const { member } = useAuth();
    const { isLoading, types } = useMemberTypes();
    const { isFound, daysTillRenew, canRenew } = useMemberLookup();
    const { setDescription, description, setMembership, agreement, setAgreement, paid, completed, grandTotal } = usePurchaseOrder();
    const {setFname, 
        setLname, 
        setPartner,
        setAddress1,
        setCity,
        setState,
        setZip,
        setCountry,
        email, setEmail} = usePersonalInfo();
    const { setType } = useMembershipInfo();
    const [steps, setSteps] = useState(membershipSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (tenant) {
            setDescription(tenant.name + ' membership');
        }
    }, [tenant])

    useEffect(() => {
        if (!isLoading && member) {
            setFname(member.fname);
            setLname(member.lname);
            setPartner(member.partner);
            setAddress1(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setCountry(member.country);
            setEmail(member.email_address);
            setType(member.type);
            const membershipType = types.find(t => t.id === member.type);
            setMembership(membershipType ? membershipType.price : 0);
            // complete the steps
            let _steps = [...steps];
            _steps[0].complete = true;
            _steps[1].complete = true;
            setSteps(_steps);
            // advance to step 2
            setCurrentStep(currentStep + 1);
        }
    }, [isLoading, member])

    useEffect(() => {
        if (isFound) {
            let _steps = [...steps];
            _steps[0].complete = true;
            _steps[1].complete = true;
            setSteps(_steps);
        }
    }, [isFound])

    useEffect(() => {
        if (agreement) {
            if (grandTotal > 0) {
                setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            } else {
                // if testing and you want to skip past payment, comment the line above and uncomment the line below - Wade
                let _steps = [...steps];
                _steps[2].complete = true;
                _steps[3].complete = true;
                setSteps(_steps);
                setCurrentStep(currentStep + 2);
            }
        } else {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
        }
    }, [completed])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <div style={{textAlign:'center',fontWeight:'600',fontSize:'24px'}}>DO NOT USE THE BROWSER BACK BUTTON</div>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <MemberLookup />}
                {currentStep === 1 && <MembershipInfo />}
                {currentStep === 2 && <StandardAgreement description={description} />}
                {currentStep === 3 &&
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 4 &&
                    <MembershipCompletionProvider>
                        <CompleteMembershipRenewal />
                    </MembershipCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} 
                        disabled={(currentStep === 0 || currentStep === steps.length - 1 || (member && currentStep === 1))}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} 
                        disabled={(currentStep === steps.length -1) || !steps[currentStep].complete || isFound && daysTillRenew > 0 || !canRenew}>Next</button>
                </div>
            </div>
            <RenewSummary />
        </div>
    )
}

export default Renew;