import React, { useEffect } from 'react';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useVendorInfo } from 'contexts/purchasing/vendorInfo';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';

function VendorInfo() {
    const { dict } = useLang();
    const { member} = useAuth()
    const {setFname, setAddress1, setCity: piSetCity, setState: piSetState, setZip: piSetZip, setPhone: piSetPhone, setEmail: piSetEmail } = usePersonalInfo();
    const {name, setName, 
        address, setAddress,
        city, setCity,
        state, setState,
        zip, setZip,
        phone, setPhone,
        email, setEmail,
        vendorType, setVendorType,
        taxId, setTaxId,
        description, setDescription,
        allowEmailMarketing, setAllowEmailMarketing} = useVendorInfo();
    
    useEffect(() => {
        if (member) {
            setName(`${member.fname} ${member.lname}`);
            setAddress(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setEmail(member.email_address);
        }
    }, [member])
    // update personal info with vendor info for ticket purchases
    useEffect(() => {
        setFname(name);
        setAddress1(address);
        piSetCity(city);
        piSetState(state);
        piSetZip(zip);
        piSetPhone(phone);
        piSetEmail(email);
    }, [name, address, city, state, zip, phone, email])

	/**
	 * render 
	 */
    return (
        <div className='personal-info'>
            <p>{dict.allFieldsRequired}</p>
            <label>{dict.name}</label>
            <input type='text' aria-required='true' value={name} data-testid='name' maxLength='64' onChange={(evt) => setName(evt.target.value)} />
            <label>Type of vendor</label>
            <select value={vendorType} onChange={evt => setVendorType(evt.target.value)}>
                <option value='1'>Swapper</option>
                <option value='2'>Food</option>
                <option value='3'>Merchandise</option>
                <option value='0'>Other</option>
            </select>
            <label>{dict.address}</label>
            <input type='text' aria-required='true' value={address} data-testid='addr' maxLength='128' onChange={(evt) => setAddress(evt.target.value)} />
            <label>{dict.city}</label>
            <input type='text' aria-required='true' value={city} data-testid='city' maxLength='64' onChange={(evt) => setCity(evt.target.value)} />
            <label>{dict.state}</label>
            <input type='text' aria-required='true' value={state} data-testid='state' maxLength='2' onChange={(evt) => setState(evt.target.value)} />
            <label>{dict.zip}</label>
            <input type='text' aria-required='true' value={zip} data-testid='zip' maxLength='10' onChange={(evt) => setZip(evt.target.value)} />
            <label>{dict.phoneNumbersOnly}</label>
            <input type='phone' aria-required='true' value={phone} data-testid='phone' maxLength='20' onChange={(evt) => setPhone(evt.target.value)} />
            <label>{dict.emailAddress}</label>
            <input type='email' aria-required='true' value={email} data-testid='email' maxLength='128' onChange={(evt) => setEmail(evt.target.value)} />
            <div>
                <input type='checkbox' checked={allowEmailMarketing} onChange={evt => setAllowEmailMarketing(evt.target.checked)} />
                <label>Opt-in to recieving marketing emails about this event</label>
            </div>
            <label>Tax ID</label>
            <input type='email' aria-required='true' value={taxId} data-testid='taxid' maxLength='20' onChange={(evt) => setTaxId(evt.target.value)} />
            <label>Describe what you will be selling</label>
            <textarea value={description} onChange={e => setDescription(e.target.value)} style={{height:'200px',width:'100%'}} maxLength='2048'></textarea>
        </div>
    )
}

export default VendorInfo;