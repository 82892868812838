import React, { useEffect, useState } from 'react';
import { useSession } from './session';
import { useCore } from './core';
import { useSearchParams } from 'react-router-dom';

const EventsContext = React.createContext(null);

function EventsProvider({availability, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [searchParams, setSearchParams] = useSearchParams();
    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);

    const redirect = searchParams.get('redirect');

    useEffect(() => {
        if (tenant) {
            getEvents();
        }
    }, [tenant])

    useEffect(() => {
        console.log('redirect ', redirect);
    }, [redirect])

    const getEvents = async () => {
        setEventsLoading(true);
        let data = {"tenant": tenant.id, availability};
        runNoAuthAction('get_upcoming_events', data, (response) => {
            if (response && response.success) {
                setEvents(response.events);
            }
            setEventsLoading(false);
        });
    };

    const provider = {
        eventsLoading,
        events,
        redirect,
    }

    return <EventsContext.Provider value={provider}>{children}</EventsContext.Provider>
}

function useEvents() {
    const context = React.useContext(EventsContext);
    if (!context) {
        throw new Error('useEvents must be used within a EventsProvider');
    }
    return context;
}

export { EventsProvider, useEvents }