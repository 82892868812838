import React, { useState, useEffect } from 'react';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useSettings } from 'contexts/settings';
import { useSession } from 'contexts/session';
import AvatarEditor from 'components/avatarEditor';

function Profile() {
    const { member } = useAuth();
    const { dict } = useLang();
    const { settings, saveSettings, updateMember } = useSettings();
    const { tenant } = useSession();
    const [avatar, setAvatar] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [title, setTitle] = useState('');
    const [partner, setPartner] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [altMemberId, setAltMemberId] = useState('');
    // member settings
    const [roster, setRoster] = useState(true);
    const [rosterPartner, setRosterPartner] = useState(true);
    const [rosterLocale, setRosterLocale] = useState(true);
    const [rosterEmail, setRosterEmail] = useState(true);
    const [rosterPhone, setRosterPhone] = useState(true);

    useEffect(() => {
        if (member) {
            setAvatar(member.avatar);
            setFname(member.fname);
            setLname(member.lname);
            setTitle(member.title);
            setPartner(member.partner);
            setAddress(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setEmail(member.email_address);
            if (member.phoneNumbers.length > 0) {
                setPhone(member.phoneNumbers[0].longCode);
            } else {
                setPhone('');
            }
            setAltMemberId(member.altMemberId);
        }
    }, [member]);

    useEffect(() => {
        if (settings) {
            setRoster(settings.roster);
            setRosterPartner(settings.rosterPartner);
            setRosterLocale(settings.rosterLocale);
            setRosterEmail(settings.rosterEmail);
            setRosterPhone(settings.rosterPhone);

        }
    }, [settings])

    const save = async () => {
        const phoneNumbers = [{longCode: phone}];
        const memberSettingsData = {memberId: member.id, roster, rosterPartner, rosterLocale, rosterEmail, rosterPhone};
        let memberData = {
            id: member.id, fname, lname, title, partner, address, city, state, zip, email_address: email, phoneNumbers,
            altMemberId, avatar}
        await saveSettings(memberSettingsData, () => {
            updateMember(memberData, (response) => {
                if (response.success) {
                    alert('Your profile changes have been saved.')
                } else {
                    alert('Error:' + response.errorMessage)
                }
            });
        });
    }

    return (
        <div className='settings-view'>
            <h3>{dict.profile}</h3>
            {tenant &&
            <div className='setting-form'>
                <AvatarEditor 
                    width='100' 
                    height='100'
                    value={avatar} 
                    onChange={setAvatar}
                />
                <label>{dict.title}</label>
                <select value={title} onChange={e => setTitle(e.target.value)}>
                    <option value=''>-None-</option>
                    <option value='Mr'>Mr</option>
                    <option value='Mrs'>Mrs</option>
                    <option value='Miss'>Miss</option>
                    <option value='Ms'>Ms</option>
                    <option value='Dr'>Dr</option>
                </select>
            
                <label>{dict.firstName}</label>
                <input type='text' value={fname} onChange={e => setFname(e.target.value)} maxLength={64} />
            
                <label>{dict.lastName}</label>
                <input type='text' value={lname} onChange={e => setLname(e.target.value)} maxLength={64} />
                    
                <label>{dict.partner}</label>
                <input type='text' value={partner} onChange={e => setPartner(e.target.value)} maxLength={64} />
                <hr/>
                <label>{dict.address}</label>
                <input type='text' value={address} onChange={e => setAddress(e.target.value)} maxLength={64} />
                
                <label>{dict.city}</label>
                <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength={64} />
            
                <label>{dict.state}</label>
                <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength={32} />
            
                <label>{dict.zip}</label>
                <input type='text' value={zip} onChange={e => setZip(e.target.value)} maxLength={16} />
                    
                <hr/>
                <label>{dict.emailOnlyOne}</label>
                <input type='text' value={email} onChange={e => setEmail(e.target.value)} maxLength={128} />
                <label>{dict.phoneNumbersOnly}</label>
                <input type='text' value={phone} onChange={e => setPhone(e.target.value)} maxLength={10} />
                {tenant.settings.alternateId && 
                    <>
                    <hr/>
                    <label>{tenant.settings.alternateIdLabel}</label>
                    <input type='text' value={altMemberId} onChange={e => setAltMemberId(e.target.value)} maxLength={64} />
                    </>
                }
                <hr/>
                {tenant?.settings.roster &&
                        <div style={{display:'flex',flexFlow:'column'}}>
                            <div><input type='checkbox' checked={roster} onChange={e => setRoster(e.target.checked)} /><label>{dict.listInRoster}</label></div>
                            <div><input type='checkbox' checked={rosterPartner} onChange={e => setRosterPartner(e.target.checked)} /> {dict.rosterPartner}</div>
                            <div><input type='checkbox' checked={rosterLocale} onChange={e => setRosterLocale(e.target.checked)} /> {dict.rosterLocale}</div>
                            <div><input type='checkbox' checked={rosterEmail} onChange={e => setRosterEmail(e.target.checked)} /> {dict.rosterEmail}</div>
                            <div><input type='checkbox' checked={rosterPhone} onChange={e => setRosterPhone(e.target.checked)} /> {dict.rosterPhone}</div>
                        </div>
                }
                <div className='button-container'>
                    <button style={getButtonStyle()} onClick={save}>{dict.save}</button>
                </div>
            </div>
            }
        </div>
    )
}

export default Profile;