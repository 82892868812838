import { useEvents } from 'contexts/events';
import { useLang } from 'contexts/lang';
import { useToken } from 'contexts/token';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { formatCurrency } from '_base';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { PurchaseOrderProvider } from 'contexts/purchasing/purchaseOrder';
import { PersonalInfoProvider } from 'contexts/purchasing/personalInfo';
import { RegistrationInfoProvider } from 'contexts/purchasing/registrationInfo';
import { WorkerInfoProvider } from 'contexts/purchasing/workerInfo';
import EventRegistration from './eventRegistration';
import Loading from 'components/loading';
import EventTickets from './eventTickets';
import EventVolunteer from './eventVolunteer';
import EventVendor from './eventVendor';
import { TicketInfoProvider } from 'contexts/purchasing/ticketInfo';
import { VendorInfoProvider } from 'contexts/purchasing/vendorInfo';
import { ConfirmationProvider } from 'contexts/purchasing/confirmation';

export async function loader({params}) {
    return {eventId: params.eventId, action: params.action};
}

function MemberEvents() {
    const { dict } = useLang();
    const { eventsLoading, events } = useEvents();
    const loaderData = useLoaderData();
    const [event, setEvent] = useState(null);

    useEffect(() => {
        if (loaderData && loaderData.eventId && events.length > 0) {
            const e = events.find(evt => evt.id == loaderData.eventId);
            setEvent(e);
        }
    }, [loaderData, events])

    return (
        <div className='events-container'>
            {loaderData ?
                <PurchaseOrderProvider type='eventPO'>
                    <ConfirmationProvider>
                        <PersonalInfoProvider>
                            <TicketInfoProvider>
                                <RegistrationInfoProvider>
                                    <WorkerInfoProvider>
                                        <VendorInfoProvider>
                                            {loaderData.action === 'register' ?
                                                <>
                                                    <div className='events-label'>Register for {event?.name}</div>
                                                    <EventRegistration event={event} />
                                                </>
                                            : loaderData.action === 'vendor' ? 
                                                <>
                                                    <div className='events-label'>Signup for {event?.name}</div>
                                                    <EventVendor event={event} />
                                                </>
                                            : loaderData.action === 'tickets' ?
                                                <>
                                                    <div className='events-label'>Purchase tickets for {event?.name}</div>
                                                    <EventTickets event={event} />
                                                </>
                                            : loaderData.action === 'volunteer' ?
                                                <>
                                                    <div className='events-label'>Volunteer for {event?.name}</div>
                                                    <EventVolunteer event={event} />
                                                </>
                                            : <div>Invalid action: {loaderData.action}</div>}
                                        </VendorInfoProvider>
                                    </WorkerInfoProvider>
                                </RegistrationInfoProvider>
                            </TicketInfoProvider>
                        </PersonalInfoProvider>
                    </ConfirmationProvider>
                </PurchaseOrderProvider>

            :
            <>
                <div className='events-label'>{dict.eventsPanelTitle}</div>
                <div className='event-panels'>
                    {eventsLoading ? <Loading/> : events.length === 0
                        ? <div className='no-events-message'>There are no upcoming events at this time, check back later.</div> 
                        :  events.map(evt => <EventPanel key={evt.id} event={evt}/>)}
                </div>
            </>
            }
        </div>
    )
}

function EventPanel({event}) {
    const [hasRegister, setHasRegister] = useState(false);
    const [hasVendor, setHasVendor] = useState(false);
    const [hasTickets, setHasTickets] = useState(false);
    const [hasWorkerSignup, setHasWorkerSignup] = useState(false);
    const { token } = useToken();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (event) {
            const availableMaps = event.maps.filter(map => map.available && moment().isAfter(map.availableStartdate) && moment().isBefore(map.availableEnddate));
            setHasRegister(
                event.registration && 
                moment().isAfter(event.reg_startdate) && 
                moment().isBefore(event.reg_enddate));
            setHasVendor(availableMaps.length > 0);
            if (event.tickets.length > 0) {
                setHasTickets(event.tickets.filter(t => moment().isAfter(t.startdate) && moment().isBefore(t.enddate)).length > 0);
            }
            setHasWorkerSignup(event.workerSignup && moment().isAfter(event.workerStartDate) && moment().isBefore(event.workerEndDate) && (token || event.publicScheduleCount > 0));
        } 
    }, [event])

    const registerHandler = () => {
        navigate(`${pathname}/${event.id}/register${search}`);
    }

    const vendorHandler = () => {
        navigate(`${pathname}/${event.id}/vendor${search}`);
    }

    const ticketHandler = () => {
        navigate(`${pathname}/${event.id}/tickets${search}`);
    }

    const volunteerHandler = () => {
        navigate(`${pathname}/${event.id}/volunteer${search}`);
    }

    return (
        <div className='event-panel'>
            <div className='event-panel-title'>{event.name}</div>
            <div className='event-panel-image'>
                {event.images.length > 0 ? <img src={event.images[0].imageData} /> : <div>No Image</div>}
            </div>
            <div className='event-panel-dates'>
                {moment(event.startDate).isSame(event.endDate, 'day') 
                    ? moment(event.startDate).format('dddd, MMMM Do YYYY')
                    : `${moment(event.startDate).format('dddd, MMMM Do YYYY')}-${moment(event.endDate).format('dddd, MMMM Do YYYY')}`}
            </div>
            {
                hasRegister && 
                    <div className='event-panel-button'>
                        <p>Pre-registation is open now until {moment(event.reg_enddate).format('dddd, MMMM Do YYYY')}.</p>
                        {event.regMemberDiscount > 0 && <p>Members get a {formatCurrency(event.regMemberDiscount)} discount at checkout when pre-registering.</p>}
                        <button onClick={registerHandler}>Register Now {formatCurrency(event.reg_price)}</button>
                    </div>
            }
            {
                hasVendor &&
                    <div className='event-panel-button'>
                        <p>Vendor/Swapper signup is available now.</p>
                        <button onClick={vendorHandler}>Signup Now</button>
                    </div>
            }
            {
                hasTickets && 
                    <div className='event-panel-button'>
                        <p>Tickets can be purchased online.</p>
                        <button onClick={ticketHandler}>Purchase Tickets Now</button>
                    </div>
            }
            {
                hasWorkerSignup && 
                    <div className='event-panel-button'>
                        <p>View and signup to be a volunteer before {moment(event.workerEndDate).format('dddd, MMMM Do YYYY')}.</p>
                        <button onClick={volunteerHandler}>Volunteer Now</button>
                    </div>
            }
            {
                !hasRegister && !hasVendor && !hasTickets && !hasWorkerSignup && 
                    <div className='event-panel-button'>
                        <p>We're sorry, but at this time registration, vendor signup, ticket sales, and volunteer sign up for this event are unavailable.</p>
                        <p>Either check back later or reach out to the event coordinators if you have questions.</p>
                    </div>
            }
        </div>
    )
}

export default MemberEvents;