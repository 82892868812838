import React from 'react';
import { useLang } from 'contexts/lang';
import Loading from 'components/loading';
import 'style/memberRides.scss';
import { useClubRides } from 'contexts/clubRides';

function ClubRides() {
    const { dict } = useLang();
    const { ridesLoading, rides, nextPage } = useClubRides();

    return (
        <div>
            {rides.length === 0 && ridesLoading ? <Loading /> : 
            <div className='events-container'>
                <div className='events-label'>
                    <div style={{display:'flex'}}>
                        Club Rides
                    </div>
                </div>
                <div className='member-rides'>
                    {rides.length === 0
                        ? <div className='no-rides'>There are no club rides, go to My Rides to add your ride.</div>
                        : rides.map(ride => 
                        <div className='member-ride'>
                            <div className='ride-header'>
                                <div className='ride-title'>{`${ride.year} ${ride.make} ${ride.model} ${ride.trim}`}</div>
                            </div>
                            <div className='ride-images'>
                                {ride.images.map(image => 
                                    <div className='ride-image' style={{backgroundImage:'url(' + image.imageData + ')'}}/>)}
                            </div>
                        </div>
                    )}
                    {rides.length === 5 && 
                        <div className='more-button-container'><button className='linkButton' onClick={() => nextPage()}>Load More</button></div>
                    }
                </div>
            </div>
            }
        </div>
    )
}

export default ClubRides;