import Loading from 'components/loading';
import { EventProvider, useEvent } from 'contexts/event';
import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { faCalendar, faEllipsisH, faHome, faLocation, faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import 'style/mobileView.scss';
import { useSession } from 'contexts/session';
import NavigationBar from 'components/navigationBar';

export async function loader({params}) {
    return params;
}

function Event() {
    const loaderData = useLoaderData();
    const [view, setView] = useState('home');

    const navButtons = [
            {id: 'home', icon: faHome, label: 'Home'},
            {id: 'schedule', icon: faCalendar, label: 'Schedule'},
            {id: 'maps', icon: faLocation, label: 'Maps'},
            {id: 'vendors', icon: faShoppingCart, label: 'Vendors'},
            {id: 'more', icon: faEllipsisH, label: 'More'},
        ];

    return (
        <div className='mobile-view within-portal'>
            <EventProvider eventId={loaderData.eventId} >
                {view === 'home' && <EventHome />}
                {view === 'schedule' && <EventSchedule />}
                {view === 'maps' && <EventMaps />}
                {view === 'vendors' && <EventVendors />}
                {view === 'more' && <EventMore />}
                <NavigationBar buttons={navButtons} selected={view} onSetView={setView} />
            </EventProvider>
        </div>
    )
}

function EventHome() {
    const { eventLoading, event } = useEvent();

    return (
        <div className='view-container home'>
            {eventLoading ? <Loading /> 
            : 
            <>
                <h1>{event.name}</h1>
                {event.images.length > 0 ? <img src={event.images[0].imageData} /> : <div>No Image</div>}
                <h3>
                    {moment(event.startDate).isSame(event.endDate, 'day') 
                        ? moment(event.startDate).format('dddd, MMMM Do YYYY')
                        : `${moment(event.startDate).format('dddd, MMMM Do YYYY')}-${moment(event.endDate).format('dddd, MMMM Do YYYY')}`}
                </h3>
                <p>{event.description}</p>
            </>
            }
        </div>
    )
}

function EventSchedule() {
    const { eventLoading, event } = useEvent();
    const [schedule, setSchedule] = useState([]);

    useEffect(() => {
        if (!eventLoading && event.schedule.length > 0) {
            setSchedule(JSON.parse(event.schedule))
        }
    }, [eventLoading])

    return (
        <div className='view-container schedule'>
            <h1>Schedule</h1>
            {schedule.length === 0 ? <div>No schedule</div> :
                <ul className='schedule-day'>
                    {schedule.map(s => 
                        <li key={s.id}>
                            <div className='day-title'>{s.title}</div>
                            <div>
                                <ul className='schedule-data'>
                                    {s.data.map(d => <li key={d.id}>{`${d.startTime} - ${d.endTime} : ${d.label}`}</li>)}
                                </ul>
                            </div>
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

function EventMaps() {
    const { eventLoading, maps } = useEvent();

    return (
        <div className='view-container maps'>
            <h1>Maps</h1>
            {maps.length === 0 ? <div>No maps</div> :
                <div>
                {maps.map(m => 
                    <div key={m.id}>
                        <div>{m.label}</div>
                        <div className='mapContainer' 
                            style={{height:'500px',
                                backgroundImage:`url(${m.backgroundImage})`,
                                backgroundSize:'contain',
                                backgroundRepeat:'no-repeat',
                                margin:'5px 0'}}
                        ></div>
                    </div>
                )}
                </div>
            }
        </div>
    )
}

function EventVendors() {
    const { eventLoading, event } = useEvent();

    return (
        <div className='view-container vendors'>
            <h1>Vendors</h1>
            {event.vendors.length === 0 ? <div>No vendors</div> :
                <ul>
                {event.vendors.map(vendor => 
                    <li key={vendor.id}>
                        {vendor.name}
                    </li>
                )}
                </ul>
            }
        </div>
    )
}

function EventMore() {
    const { event } = useEvent();
    const { tenant } = useSession();
    const navigate = useNavigate();

    return (
        <div className='view-container more'>
            <h1>More</h1>
            <p>The {event.name} is put on by the {tenant.name} club. If you like this show
                and want to become a member of the club, consider joining.
            </p>
            <button onClick={() => navigate('/portal/join')}>Join the club</button>
        </div>
    )
}

export default Event;