import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useLang } from 'contexts/lang';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { useSession } from 'contexts/session';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useMembershipInfo } from 'contexts/purchasing/membershipInfo';
import { useEventCompletion } from 'contexts/eventCompletion';
import { useMembershipCompletion } from 'contexts/membershipCompletion';
import { useProductsCompletion } from 'contexts/productsCompletion';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';
import { useVendorInfo } from 'contexts/purchasing/vendorInfo';

function CompleteEvent({eventId}) {
    const { tenant } = useSession();
    const { registrations, spaces, tickets, selectedShifts, setCompleted } = usePurchaseOrder();
    const { fname, lname, address1, address2, city, state, zip, country, phone, email, allowEmailMarketing } = usePersonalInfo();
    const { name, address, city: vendorCity, state: vendorState, zip: vendorZip, 
        phone: vendorPhone, email: vendorEmail, allowEmailMarketing: vendorAllowEmailMarketing, vendorType, taxId, description} = useVendorInfo();
    const { fname: workerFname, lname: workerLname, phone: workerPhone, email: workerEmail, allowEmailMarketing: workerAllowEmailMarketing, swag } = useWorkerInfo();
    const { dict } = useLang();
    const { createEntry, createVendor, createTicket, workerSignup } = useEventCompletion();
    
    const [ticketQuantity, setTicketQuantity] = useState(0);
    const [creatingEntry, setCreatingEntry] = useState(false);
    const [creatingSpaces, setCreatingSpaces] = useState(false);
    const [creatingTickets, setCreatingTickets] = useState(false);
    const [signingUp, setSigningUp] = useState(false);
    const [error, setError] = useState(null);

    const [completedEntry, setCompletedEntry] = useState(true);
    const [completedSpaces, setCompletedSpaces] = useState(true);
    const [completedTickets, setCompletedTickets] = useState(true);
    const [completedSignup, setCompletedSignup] = useState(true);

    useEffect(() => {
        if (registrations.length > 0) {
            setCompletedEntry(false);
            createEntryHandler();
        }
        if (spaces.length > 0) {
            setCompletedSpaces(false);
            createSpacesHandler();
        }
        if (tickets.length > 0) {
            const quantity = tickets.reduce((a, c) => a + (parseInt(c.quantity)), 0);
            if (quantity > 0) {
                setTicketQuantity(quantity);
                setCompletedTickets(false);
                createTicketHandler();
            }
        }
        if (selectedShifts.length > 0) {
            setCompletedSignup(false);
            signUp();
        }
    }, [])

    useEffect(() => {
        setCompleted(completedEntry && completedSpaces && completedTickets && completedSignup);
    }, [completedEntry, completedSpaces, completedTickets, completedSignup])
    /*
        Create registration entries
    */
    const createEntryHandler = async () => {
        setCreatingEntry(true);
        let entries = [];
        registrations.forEach(entry => {
            entries.push({...entry, fname, lname, address1, address2, city, state, zip, country, phone, email, allowEmailMarketing, notes: 'DART:Online Purchase'});
        });
        const data = {tenantUUID: tenant.uuid, eventId, entries};
        await createEntry(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingEntry(false);
            setCompletedEntry(true);    
        });
    }

    const createSpacesHandler = async () => {
        setCreatingSpaces(true);
        const vendor = {
            eventId, 
            name, 
            address, 
            city: vendorCity, 
            state: vendorState, 
            zip: vendorZip, 
            phone: vendorPhone, 
            email: vendorEmail, 
            allowEmailMarketing: vendorAllowEmailMarketing,
            vendorType,
            taxId,
            description: `[Online Purchase] ${description}`};
        const elements = spaces.map(s => ({eventMapId: s.eventMapId, elementId: s.elem}));
        let data = {tenantId: tenant.id, eventId, vendor, reservations: elements};
        await createVendor(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingSpaces(false);
            setCompletedSpaces(true);
        });
    }
    /*
        create tickets
    */
    const createTicketHandler = async () => {
        setCreatingTickets(true);
        let data = {
            tenantUUID: tenant.uuid,
            event: eventId,
            tickets: tickets,
            name: fname + ' ' + lname,
            address: address1,
            city: city,
            state: state,
            zip: zip,
            email: email,
            phone: phone,
            allowEmailMarketing: allowEmailMarketing,
        };
        await createTicket(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingTickets(false);
            setCompletedTickets(true);
        });
    }
    /* 
        sign up for shifts
    */
    const signUp = async () => {
        setSigningUp(true);
        const contactInfo = {fname: workerFname, lname: workerLname, email: workerEmail, allowEmailMarketing: workerAllowEmailMarketing, phone: workerPhone, swag, notes: 'Online signup'}
        let data = {
            tenantId: tenant.id, 
            eventId: eventId,
            contactInfo: contactInfo, 
            shifts: selectedShifts.map(s => s.id)}
        await workerSignup(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setSigningUp(false);
            setCompletedSignup(true);
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            {registrations.length > 0 &&
                <div className='completeSection'>
                    {creatingEntry ?
                        <div>
                            <h2>{dict.processingRegistration1} {fname}</h2>
                            <p>{dict.processingRegistration2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{dict.processingRegistration3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {spaces.length > 0 &&
                <div className='completeSection'>
                    {creatingSpaces ?
                        <div>
                            <h2>{dict.spaces1.replace('{0}' , spaces.length).replace('{1}', fname)}</h2>
                            <p>{dict.spaces2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin/></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{spaces.length} {dict.spaces3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {ticketQuantity > 0 &&
                <div className='completeSection'>
                    {creatingTickets ? 
                        <div>
                            <h2>{dict.ticket1.replace('{0}' , ticketQuantity).replace('{1}', fname)}</h2>
                            <p>{dict.ticket2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin/></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{ticketQuantity} {dict.ticket3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {selectedShifts.length > 0 &&
                <div className='completeSection'>
                    {signingUp ?
                        <div>
                            <h2>{dict.processingWorkerSignup1} {fname}</h2>
                            <p>{dict.processingWorkerSignup2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{dict.processingWorkerSignup3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

function CompleteMembership() {
    const { tenant } = useSession();
    const { setCompleted } = usePurchaseOrder();
    const { fname, lname, partner, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { emailNewsletter, mailNewsletter, listInDirectory, comments, notify, type, tags} = useMembershipInfo();
    const { dict } = useLang();
    const { createMembership } = useMembershipCompletion();
    const [error, setError] = useState(null);

    const [creatingMembership, setCreatingMembership] = useState(false);

    useEffect(() => {
        createMembershipHandler();
    }, [])

    const createMembershipHandler = async() => {
        setCreatingMembership(true);
        let data = {
            'tenantUUID': tenant.uuid,
			'fname': fname,
			'lname': lname,
            'partner': partner,
			'address': address1 + ' ' + address2,
			'city': city,
			'state': state,
			'zip': zip,
		    'phoneNumbers': [],
			'email_address': email,
			'email_newsletter': emailNewsletter,
			'mail_newsletter': mailNewsletter,
		    'list_in_directory': listInDirectory,
			'comments': comments,
			'notify': notify,
			'type': type,
			'tags': tags
        };
        if (phone.length > 0) {
            data.phoneNumbers.push({'typeId': 1, 'isoCode': 'US', 'countryCode': '+1', 'longCode': phone});
        }
        await createMembership(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingMembership(false);
            setCompleted(true);    
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                {creatingMembership ?
                    <div>
                        <h2>{dict.processingMembership1} {fname}</h2>
                        <p>{dict.processingMembership2}</p>
                        <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                    </div>
                    :
                    <div>
                        <h2>{dict.thankYou}</h2>
                        <p>{dict.processingMembership3}</p>
                        <p>{dict.clickFinished}</p>
                        <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    </div>    
                }
            </div>
        </div>
    )
}

function CompleteMembershipRenewal() {
    const { tenant } = useSession();
    const { years, setCompleted } = usePurchaseOrder();
    const { fname, lname, partner, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { emailNewsletter, mailNewsletter, listInDirectory, comments, notify, type, tags, autoRenew} = useMembershipInfo();
    const { dict } = useLang();
    const { createMembership } = useMembershipCompletion();
    const [error, setError] = useState(null);

    const [creatingMembership, setCreatingMembership] = useState(false);

    useEffect(() => {
        createMembershipHandler();
    }, [])

    const createMembershipHandler = async() => {
        setCreatingMembership(true);
        let data = {
            'tenantUUID': tenant.uuid,
			'fname': fname,
			'lname': lname,
            'partner': partner,
			'address': address1 + ' ' + address2,
			'city': city,
			'state': state,
			'zip': zip,
		    'phoneNumbers': [],
			'email_address': email,
			'email_newsletter': emailNewsletter,
			'mail_newsletter': mailNewsletter,
		    'list_in_directory': listInDirectory,
			'comments': comments,
			'notify': notify,
			'type': type,
			'tags': tags,
            'years': years,
            'autoRenew': autoRenew
        };
        if (phone.length > 0) {
            data.phoneNumbers.push({'typeId': 1, 'isoCode': 'US', 'countryCode': '+1', 'longCode': phone});
        }
        await createMembership(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingMembership(false);
            setCompleted(true);    
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                {creatingMembership ?
                    <div>
                        <h2>{dict.processingMembership1} {fname}</h2>
                        <p>{dict.processingMembership2}</p>
                        <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                    </div>
                    :
                    <div>
                        <h2>{dict.thankYou}</h2>
                        <p>{dict.renewedMembership}</p>
                        <p>{dict.clickFinished}</p>
                        <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    </div>    
                }
            </div>
        </div>
    )
}

function CompleteDonation() {
    const { setCompleted } = usePurchaseOrder();
    //const { fname, lname, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { dict } = useLang();
    const [error, setError] = useState(null);

    useEffect(() => {
        setCompleted(true);
    }, [])

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                <div>
                    <h2>{dict.thankYou}</h2>
                    <p>{dict.donationThanks}</p>
                    <p>{dict.clickFinished}</p>
                    <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                </div>
            </div>
        </div>
    )
}

function CompleteProducts() {
    const { tenant } = useSession();
    const { products, setCompleted } = usePurchaseOrder();
    const { fname, lname, address1, city, state, zip, phone, email } = usePersonalInfo();
    const { dict } = useLang();
    const { sellProducts } = useProductsCompletion();
    const [error, setError] = useState(null);
    const [sellingProduct, setSellingProduct] = useState(false);

    useEffect(() => {
        sellProductsHandler();
    }, [])

    const sellProductsHandler = async () => {
        setSellingProduct(true);
        let cart = [];
        products.forEach((p) => {
            let inv = p.inventories[0];
            inv.productName = p.name;
            inv.productId = p.id;
            cart.push({inventory: inv, quantity: 1});
        });
        let data = { 
            'tenant': tenant.id,
            'cart': cart,
            //"promos": null,
            'shipping': {name: fname + ' ' + lname, address: address1, city, state, zip, phone, email},
            //"receipt": receipt
        };
        await sellProducts(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setSellingProduct(false);
            setCompleted(true);    
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                {sellingProduct ? 
                    <div>
                        <h2>Processing order...</h2>
                        <p>Do not hit your browser back button or close the window until this has completed.</p>
                        <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                    </div>
                :
                    <div className='shipping-info'>
                        <h2>{dict.thankYou}</h2>
                        <p>{dict.purchaseComplete}</p>
                        <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    </div>
                }
            </div>
        </div>
    )
}

export { CompleteEvent, CompleteMembership, CompleteMembershipRenewal, CompleteDonation, CompleteProducts }