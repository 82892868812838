import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import Payment from 'purchasing/payment';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import { CompleteProducts } from 'purchasing/complete';
import { useAuth } from 'contexts/auth';
import { ProductsCompletionProvider } from 'contexts/productsCompletion';
import ProgressIndicator from 'components/progressIndicator';
import PersonalInfo from 'purchasing/personalInfo';
import PurchaseSummary from './purchaseSummary';
import { toSpliced } from '_base';
import StandardAgreement from 'purchasing/standardAgreement';

const cartSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function PurchaseCart() {
    const { tenant } = useSession();
    const { member } = useAuth();
    const { setMemberId, setFname, setLname, setAddress1, setCity, setState, setZip, 
        setPhone, email, setEmail, isPiComplete } = usePersonalInfo();
    const { setDescription, description, agreement, setAgreement, paid, completed } = usePurchaseOrder();
    const [steps, setSteps] = useState(cartSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        setDescription(`${tenant.name} store purchase`);
    }, [])

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
            setFname(member.fname);
            setLname(member.lname);
            setAddress1(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setPhone(member.phone);
            setEmail(member.email);
        }
    }, [member])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        } else {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[3], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

/*
    const renderCartTotal = () => {
        return <div className='cart-total-view'>
                    <div className='cart-total'>{dict.cartSubtotal.replace('{0}', products.length)} {formatCurrency(productsTotal)}</div>
                    <div className='cart-total'>{dict.cartShipping} {formatCurrency(shippingTotal)}</div>
                    <div className='cart-total'>{dict.cartDiscount} {formatCurrency(discountTotal)}</div>
                    <div className='cart-total'>{dict.cartFee} {formatCurrency(tenant.settings.serviceFee)}</div>
                    <hr />
                    <div className='cart-total'>{dict.cartTotal} {formatCurrency(grandTotal)}</div>
                    <div>
                        <button style={getButtonStyle()} onClick={prevView}>{dict.backToStore}</button>
                        <button style={getButtonStyle()} onClick={nextView}>{dict.continue}</button>
                    </div>
                </div>
    }
*/

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <PersonalInfo />}
                {currentStep === 1 && <StandardAgreement description={description} />}
                {currentStep === 2 && 
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 3 &&
                    <ProductsCompletionProvider>
                        <CompleteProducts />
                    </ProductsCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <PurchaseSummary />
        </div>
    )
}

export default PurchaseCart;