import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const EventContext = React.createContext(null);

function EventProvider({eventId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [eventLoading, setEventLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [eventTickets, setEventTickets] = useState([]);
    const [maps, setMaps] = useState([]);

    useEffect(() => {
        if (tenant) {
            getEvent();
        }
    }, [tenant])

    const getEvent = async () => {
        setEventLoading(true);
        await runNoAuthAction('get_event', {tenantId: tenant.id, event: eventId}, response => {
            setEvent(response.event);
            setEventTickets(response.eventtickets);
            setMaps(response.maps);
            setEventLoading(false);
        })
    }

    const provider = {
        eventLoading,
        event,
        eventTickets,
        maps,
    }

    return <EventContext.Provider value={provider}>{children}</EventContext.Provider>
}

function useEvent() {
    const context = React.useContext(EventContext);
    if (!context) {
        throw new Error('useEvent must be used within an EventProvider');
    }
    return context;
}

export { EventProvider, useEvent }