import { useAuth } from 'contexts/auth';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';
import React, { useEffect, useState } from 'react';
import ProgressIndicator from 'components/progressIndicator';
import PersonalInfo from 'purchasing/personalInfo';
import RegistrationInfo from 'purchasing/registrationInfo';
import RegistrationSummary from './registrationSummary';
import { ClassesProvider } from 'contexts/purchasing/classes';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import Payment from 'purchasing/payment';
import { EventCompletionProvider } from 'contexts/eventCompletion';
import { CompleteEvent } from 'purchasing/complete';
import { toSpliced } from '_base';
import moment from 'moment';
import Agreement from 'purchasing/eventAgreement';
import { useConfirmation } from 'contexts/purchasing/confirmation';
import TicketPicker from 'purchasing/ticketPicker';

const registrationSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'register', label: 'Registration Info', complete: false, state: 'todo'},
    {id: 'confirm', label: 'Confirm', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];
function EventRegistration({event}) {
    const { setDescription, setRegistrationPrice, setDiscountTotal, agreement, paid, completed } = usePurchaseOrder();
    const { member } = useAuth();
    const { setMemberId, isPiComplete, email } = usePersonalInfo();
    const { isRegComplete, addRegistration } = useRegistrationInfo();
    const { confirmed, setConfirmed } = useConfirmation();
    const [steps, setSteps] = useState(registrationSteps);
    const [currentStep, setCurrentStep] = useState(0);
    const [hasTickets, setHasTickets] = useState(false);

    useEffect(() => {
        if (event) {
            setDescription(event.name);
            setRegistrationPrice(event.reg_price);
            if (member) {
                setDiscountTotal(event.regMemberDiscount);
            }
            if (moment().isBefore(moment(event.reg_startdate))) {
                alert('Online event registration will begin on ' + moment(event.reg_startdate).format('dddd, MMMM Do YYYY') + ', please come back then.');
                window.location.href=window.location.origin;
            }
            if (moment().isAfter(moment(event.reg_enddate))) {
                alert(`We're sorry, online event registration ended on ${moment(event.reg_enddate).format('dddd, MMMM Do YYYY')}.`);
                window.location.href=window.location.origin;
            }
            if (event.tickets.length > 0) {
                setHasTickets(event.tickets.filter(t => moment().isAfter(t.startdate) && moment().isBefore(t.enddate)).length > 0);
            }
        }
    }, [event])

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
        }
    }, [member])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true})); 
        }
    }, [isPiComplete])

    useEffect(() => {
        if (isRegComplete) {
            // proceed to the next step
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [isRegComplete])

    useEffect(() => {
        if (confirmed) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            // proceed to the next step
            setCurrentStep(currentStep+1);
        }
    }, [confirmed])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
        } else {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 5, 1, {...steps[5], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        if (currentStep === 2) {
            // add the current registration to the array of registration
            // -- this resets the registration info --
            addRegistration();
        }
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    const handleRegisterAnotherVehicle = () => {
        // go back to the step to enter the car info
        setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: false}));
        setCurrentStep(1);
    }

    return (
        <div className='event-registration'>
            {(event && moment().isAfter(moment(event.reg_startdate)) && moment().isBefore(moment(event.reg_enddate))) ?
                <>
                <div className='registration-steps'>
                    <ProgressIndicator steps={steps} activeStep={currentStep} />
                    {currentStep === 0 && <PersonalInfo showOptIn={true} />}
                    {currentStep === 1 && 
                        <ClassesProvider eventId={event.id}>
                            <RegistrationInfo event={event} />
                        </ClassesProvider>
                    }
                    {currentStep === 2 && 
                        <>
                            <div className='button-container' style={{justifyContent:'flex-start'}}>
                                <button onClick={handleRegisterAnotherVehicle}>Register another vehicle</button>
                            </div>
                            {hasTickets && 
                                <>
                                    <p>Or purchase tickets</p>
                                    <TicketPicker event={event}/>
                                </>
                            }
                            <p>Or click the &quot;Continue Purchase&quot; button when you are ready to agree to the terms of the sale and complete the purchase.
                                Click &quot;Cancel&quot; on the right to cancel this purchase. Do not use the browser back button.
                            </p>
                            <div className='button-container' style={{justifyContent:'flex-start'}}>
                                <button onClick={() => setConfirmed(true)}>Continue Purchase</button>
                            </div>
                        </>
                    }
                    {currentStep === 3 && <Agreement event={event} />}
                    {currentStep === 4 &&
                        <PaymentLoaderProvider email={email}>
                            <Payment />
                        </PaymentLoaderProvider>
                    }
                    {currentStep === 5 &&
                        <EventCompletionProvider>
                            <CompleteEvent eventId={event.id} />
                        </EventCompletionProvider>
                    }
                    <div className='registration-buttons'>
                        <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                        <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                    </div>
                </div>
                {event && <RegistrationSummary />}
                </>
                :
                <p>Registration for this event is not available at this time.</p>
            }
        </div>
    )
} 

export default EventRegistration;