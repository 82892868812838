import { useMyRides } from 'contexts/myRides';
import React, { useState } from 'react';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import Loading from 'components/loading';
import RideCreator from './rideCreate';
import 'style/memberRides.scss';
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import IconButton from 'components/iconButton';

function MyRides() {
    const { dict } = useLang();
    const { ridesLoading, rides, deleteRide } = useMyRides();
    const [creating, setCreating] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [ride, setRide] = useState(null);

    const createHandler = () => {
        setRide(null);
        setCreating(true);
    }

    const editRideHandler = (r) => {
        setRide(r);
        setUpdating(true);
    }

    const rideCreatorDoneHandler = () => {
        setRide(null);
        setCreating(false);
        setUpdating(false);
    }

    const deleteRideHandler = (r) => {
        deleteRide(r.id);
    }

    return (
        <div>
            {ridesLoading ? <Loading /> : (creating || updating) ? <RideCreator ride={ride} onDone={rideCreatorDoneHandler} /> : 
            <div className='events-container'>
                <div className='events-label'>
                    <div style={{display:'flex'}}>
                        My Rides
                    </div>
                    <button style={getButtonStyle()} onClick={createHandler}>Add a ride</button>
                </div>
                <div className='member-rides'>
                    {rides.length === 0
                        ? <div className='no-rides'>Click the Add a ride button to add your ride.</div>
                        : rides.map(ride => 
                        <div className='member-ride'>
                            <div className='ride-header'>
                                <div className='ride-title'>{`${ride.year} ${ride.make} ${ride.model} ${ride.trim}`}</div>
                                <div style={{display:'flex'}}>
                                    <IconButton icon={faPencil} label='Edit' onClick={() => editRideHandler(ride)} />
                                    <IconButton icon={faTrash} label='Delete' onClick={() => deleteRideHandler(ride)} />
                                </div>
                            </div>
                            <div className='ride-images'>
                                {ride.images.map(image => 
                                    <div className='ride-image' style={{backgroundImage:'url(' + image.imageData + ')'}}/>)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            }
        </div>
    )
}

export default MyRides;