import React, { useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import ProgressIndicator from 'components/progressIndicator';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import Payment from 'purchasing/payment';
import { EventCompletionProvider } from 'contexts/eventCompletion';
import { CompleteEvent } from 'purchasing/complete';
import TicketPicker from 'purchasing/ticketPicker';
import TicketSummary from './ticketSummary';
import { toSpliced } from '_base';
import TicketInfo from 'purchasing/ticketInfo';
import Agreement from 'purchasing/eventAgreement';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';

const registrationSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'register', label: 'Select Tickets', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function EventTickets({event}) {
    const { setDescription, tickets, agreement, paid, completed, grandTotal } = usePurchaseOrder();
    const { member } = useAuth();
    const { setMemberId, isPiComplete, email } = usePersonalInfo();
    const [steps, setSteps] = useState(registrationSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (event) {
            setDescription(event.name + ' tickets');
        }
    }, [event])

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
        }
    }, [member])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (tickets.length > 0) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [tickets])

    useEffect(() => {
        if (agreement) {
            if (grandTotal > 0) {
                setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            } else {
                let _steps = [...steps];
                _steps[2].complete = true;
                _steps[3].complete = true;
                setSteps(_steps);
                setCurrentStep(currentStep + 2);
            }
        } else {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: false}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <TicketInfo />}
                {currentStep === 1 && 
                    <TicketPicker event={event} isPublic={true} />
                }
                {currentStep === 2 && <Agreement event={event} />}
                {currentStep === 3 &&
                    <PaymentLoaderProvider email={email}>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 4 &&
                    <EventCompletionProvider>
                        <CompleteEvent eventId={event.id} />
                    </EventCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <TicketSummary />
        </div>
    )
}

export default EventTickets;