import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ClubRidesContext = React.createContext(null);

function ClubRidesProvider({limit, children}) {
    const { runAction } = useCore();
    const [ridesLoading, setRidesLoading] = useState(true);
    const [rides, setRides] = useState([]);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        loadRides();
    }, [offset])

    const loadRides = async() => {
        setRidesLoading(true);
        await runAction('get_all_member_rides', {limit, offset}, response => {
            setRides(rides.concat(response.rides));
            setRidesLoading(false);
        })
    }

    const provider = {
        ridesLoading,
        rides,
        setOffset,
        nextPage: () => setOffset(offset + limit)
    }

    return <ClubRidesContext.Provider value={provider}>{children}</ClubRidesContext.Provider>
}

function useClubRides() {
    const context = React.useContext(ClubRidesContext);
    if (!context) {
        throw new Error('useClubRides must be used within a ClubRidesProvider');
    }
    return context;
}

export { ClubRidesProvider, useClubRides }