import { useEffect } from 'react';
import React, { useState } from 'react';
import { formatCurrency, toSpliced } from '_base';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

function TicketPicker({event, isPublic = false}) {
    const { tickets, setTickets } = usePurchaseOrder();

    //useEffect(() => {
    //    console.log(tickets);
    //}, [tickets]);

    const changeTicketHandler = (data) => {
        const idx = tickets.findIndex(elem => elem.ticket.id === data.ticket.id);
        if (idx === -1) {
            setTickets([...tickets, data]);
        } else {
            setTickets(toSpliced(tickets, idx, 1, data));
        }
    }

    return (
        <div>
            <ul className='ticket-picker'>
                {event.tickets.map(t => {
                    if ((t.availability === 0 && isPublic) || !isPublic) {
                        return <TicketItem key={t.id} ticket={t} onChange={changeTicketHandler} />
                    } else {
                        return null;
                    }
                })}
            </ul>
        </div>
    )
}

function TicketItem({ticket, onChange}) {
    const [quantity, setQuantity] = useState(0);
    const [values, setValues] = useState([]);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        const data = {
            ticket, quantity, values
        }
        onChange(data);
    }, [quantity, values]);

    const updateTicketValue = (ticketIndex, value) => {
        const valueIdx = values.findIndex(v => v.value.optionId === value.optionId && v.ticketIndex === ticketIndex);
        console.log('optionId:', value.optionId, ', valueIdx:', valueIdx);
        if (valueIdx == -1) {
            setValues([...values, {ticketIndex, value}]);
        } else {
            setValues(toSpliced(values, valueIdx, 1, {ticketIndex, value}));
        }
    }

    return (
        <li>
            <div className='ticket-details'>
                <div className='ticket-info'>
                    <input type='number' value={quantity} onChange={evt => setQuantity(evt.target.value)} min={0} max={ticket.limit} step={1} />
                    <div>{ticket.description}</div> 
                    <div style={{marginLeft:'10px'}}>{formatCurrency(ticket.price)}</div>
                    <div style={{marginLeft:'10px'}}>{ticket.info && ticket.info.length > 0 && 
                        <FontAwesomeIcon icon={faInfoCircle} style={{color:'#2599C4',cursor:'pointer'}} onClick={() => setShowInfo(!showInfo)}/>}</div>
                </div>
                <div className='ticket-values'>
                {ticket.options.length > 0 && quantity > 0 &&
                    <>
                        {[...Array(parseInt(quantity))].map((_, ticketIndex) => 
                            <div key={`ticket-${ticketIndex}`}>
                                {ticket.options.map((opt) => 
                                    <TicketValueItem 
                                        key={`${opt.id}-${ticketIndex}`} 
                                        option={opt} 
                                        onChange={value => updateTicketValue(ticketIndex, value)} />
                                )}
                            </div>
                        )}
                    </>
                }
                </div>
            </div>
            <div className='ticket-info-container' style={{display:showInfo ? 'flex' : 'none'}}>
                <p>{ticket.info}</p>
            </div>
        </li>
    )
}

function TicketValueItem({option, onChange}) {
    const [value, setValue] = useState({value: '', optionId: option.id});

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <div className='ticket-value'>
            <div className='ticket-value-label'>
                <div>{option.label}</div>
                {option.subLabel.length > 0 &&
                    <div className='sub-label'> ({option.subLabel})</div>
                }
            </div>
            {option.type === 1 ?
                <input type='text' value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})} maxLength={64} />
                : option.type === 2 ?
                <select value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})}>
                    <option value=''>-Choose one-</option>
                    {option.choices.map(choice =>
                        <option key={choice.id} value={choice.label}>{choice.label}</option>
                    )}
                </select>
                : null
            }
        </div>
    )
}

export default TicketPicker;