import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from 'routes/public/home';
import Join, {loader as joinLoader} from 'routes/public/join';
import Renew from 'routes/public/renew';
import Event, {loader as EventLoader} from 'routes/portal/event';
//import Events from 'routes/events';
import MemberEvents, {loader as eventActionLoader} from 'routes/portal/events/memberEvents';
import Signin from 'routes/signin/signin';
import Donate from 'routes/public/donate';
import { LangProvider } from 'contexts/lang';
import { SessionProvider } from 'contexts/session';
import { TokenProvider } from 'contexts/token';
import ErrorPage from 'error-page';

import 'style/main.scss';
import { CoreProvider } from 'contexts/core';
import { AuthProvider } from 'contexts/auth';
// portal
import Social from 'routes/portal/social/social';
import Store, {loader as itemLoader} from 'routes/portal/store/store';
import Files from 'routes/portal/files/files';
import Activities from 'routes/portal/activities/activities';
import Classifieds, {loader as listingLoader} from 'routes/portal/classifieds/classifieds';
import Roster from 'routes/portal/roster/roster';
import Rides from 'routes/portal/rides';
import { FilesProvider } from 'contexts/files';
import { ActivitiesProvider, loader as activitiesLoader } from 'contexts/activities';
import { StoreProvider } from 'contexts/store';
import { RosterProvider } from 'contexts/roster';
import { ClassifiedsProvider } from 'contexts/classifieds';
import { SocialProvider } from 'contexts/social';
import { SettingsProvider } from 'contexts/settings';
import { PurchaseOrderProvider } from 'contexts/purchasing/purchaseOrder';
import PortalRoot from 'routes/portalRoot';
import { SigninProvider } from 'contexts/signin';
import reportWebVitals from './reportWebVitals';
import { StripeProvider } from 'contexts/stripe';
import { EventsProvider } from 'contexts/events';
import { MemberTypesProvider } from 'contexts/memberTypes';
import Profile from 'routes/portal/profile/profile';
import Membership from 'routes/portal/membership';
import Payments from 'routes/portal/payments';
import Notifications from 'routes/portal/notifications';
import Password from 'routes/portal/password';
import Children from 'routes/portal/settings';
import Logout from 'routes/logout';
import VoterBallot, {loader as voterBallotLoader} from 'routes/public/voterBallot';

const router = createBrowserRouter([
    {path: '/', 
        element: <AuthProvider><PortalRoot /></AuthProvider>,
        errorElement: <ErrorPage />,
        children: [
            {path: 'portal', element: <Home />},
            {path: 'portal/join', element: <MemberTypesProvider><Join /></MemberTypesProvider>},
            {   
                path: 'portal/join/:typeId', 
                element: <MemberTypesProvider><Join /></MemberTypesProvider>,
                loader: joinLoader,
            },
            {path: 'portal/renew', element: <MemberTypesProvider><Renew /></MemberTypesProvider>},
            {path: 'portal/signin', element: <SigninProvider><Signin /></SigninProvider>},
            {path: 'portal/events', element: <EventsProvider availability='0'><MemberEvents /></EventsProvider>},
            {
                path: 'portal/events/:eventId/:action',
                element: <EventsProvider availability='1'><MemberEvents /></EventsProvider>,
                loader: eventActionLoader,
            },
            {path: 'portal/donate', element: <Donate />},
            {path: 'portal/logout', element: <Logout />},
            {path: 'portal/voterballot/:voterid/:eventid', element: <VoterBallot />, loader: voterBallotLoader},
            {path: 'portal/event/:eventId', element: <Event />, loader: EventLoader},
        ]
    },
    {path: '/portal/member',
        element: <AuthProvider>
                <SettingsProvider>
                    <StripeProvider>
                        <PortalRoot />
                    </StripeProvider>
                </SettingsProvider>
            </AuthProvider>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: 'social', 
                element: <SocialProvider><Social /></SocialProvider>
            },
            {
                path: 'activities', 
                element: <ActivitiesProvider><Activities /></ActivitiesProvider>
            },
            {
                path: 'activities/:start/:end', 
                element: <ActivitiesProvider><Activities /></ActivitiesProvider>,
                loader: activitiesLoader,
            },
            {
                path: 'events',
                element: <EventsProvider availability='1'><MemberEvents /></EventsProvider>
            },
            {
                path: 'events/:eventId/:action',
                element: <EventsProvider availability='1'><MemberEvents /></EventsProvider>,
                loader: eventActionLoader,
            },
            {path: 'rides', element: <Rides />},
            {path: 'files', element: <FilesProvider><Files /></FilesProvider>},
            {
                path: 'store', 
                element: <PurchaseOrderProvider type='StorePO'>
                    <StoreProvider>
                        <EventsProvider>
                            <Store />
                        </EventsProvider>
                    </StoreProvider>
                </PurchaseOrderProvider>
            },
            {
                path: 'store/:itemId', 
                element: <PurchaseOrderProvider type='StorePO'><StoreProvider><Store /></StoreProvider></PurchaseOrderProvider>,
                loader: itemLoader,
            },
            {
                path: 'classifieds', 
                element: <ClassifiedsProvider><Classifieds /></ClassifiedsProvider>
            },
            {
                path: 'classifieds/:listingId', 
                element: <ClassifiedsProvider><Classifieds /></ClassifiedsProvider>,
                loader: listingLoader
            },
            {path: 'roster', element: <RosterProvider><Roster /></RosterProvider>},
            {path: 'profile', element: <Profile />},
            {path: 'membership', element: <Membership />},
            {path: 'payments', element: <Payments />},
            {path: 'notifications', element: <Notifications />},
            {path: 'password', element: <Password />},
            {path: 'children', element: <Children />},
        ]

    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LangProvider>
            <TokenProvider>
                <CoreProvider>
                    <SessionProvider>
                        <RouterProvider router={router} />
                    </SessionProvider>
                </CoreProvider>
            </TokenProvider>
        </LangProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();