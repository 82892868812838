import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from './purchaseOrder';
import { usePersonalInfo } from './personalInfo';

const TicketInfoContext = React.createContext(null);

const PIKEY = 'ticketInfo';

function TicketInfoProvider({children}) {
    const { completed } = usePurchaseOrder();
    const { setFname: piSetFname, setLname: piSetLname, setEmail: piSetEmail } = usePersonalInfo();
    const [isLoading, setIsLoading] = useState(true);
    const [memberId, setMemberId] = useState(null);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [allowEmailMarketing, setAllowEmailMarketing] = useState(true);
    const [isTiComplete, setIsTiComplete] = useState(false);

    useEffect(() => {
        setIsTiComplete(name?.length > 0 
            && address?.length > 0 
            && city?.length > 0 
            && state?.length > 0 
            && zip?.length > 0 
            && email?.length > 0
            && phone?.length > 0);
        piSetFname(name);
        
        piSetEmail(email);
    }, [name, address, city, state, zip, email, phone])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const piJSONString = JSON.stringify({memberId, name, address, city, state, zip, email, phone, allowEmailMarketing});
            window.sessionStorage.setItem(PIKEY, piJSONString);
        }
    }, [memberId, name, address, city, state, zip, email, phone])

    useEffect(() => {
        if (window.sessionStorage) {
            const piJSONString = window.sessionStorage.getItem(PIKEY);
            if (piJSONString) {
                try {
                    const piJSON = JSON.parse(piJSONString);
                    setMemberId(piJSON.memberId);
                    setName(piJSON.name);
                    setAddress(piJSON.address);
                    setCity(piJSON.city);
                    setState(piJSON.state);
                    setZip(piJSON.zip);
                    setEmail(piJSON.email);
                    setPhone(piJSON.phone);
                    setAllowEmailMarketing(piJSON.allowEmailMarketing);
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(PIKEY);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(PIKEY);
        }
    }, [completed])

    const resetTi = () => {
        window.sessionStorage.removeItem(PIKEY);
    }

    const provider = {
        memberId, setMemberId,
        name, setName,
        address, setAddress,
        city, setCity,
        state, setState,
        zip, setZip,
        email, setEmail,
        phone, setPhone,
        allowEmailMarketing, setAllowEmailMarketing,
        isTiComplete,
        resetTi
    }

    return <TicketInfoContext.Provider value={provider}>{children}</TicketInfoContext.Provider>
}

function useTicketInfo() {
    const context = React.useContext(TicketInfoContext);
    if (!context) {
        throw new Error('useTicketInfo must be used within a TicketInfoProvider');
    }
    return context;
}

export { TicketInfoProvider, useTicketInfo }