import React from 'react';
import IconButton from 'components/iconButton';

function NavigationBar({ buttons, selected, onSetView }) {

    return (
        <div className='navigation-bar'>
            {buttons.map(btn => 
                <div className={`navigation-button ${selected === btn.id ? ' selected' : ''}`}>
                    <IconButton icon={btn.icon} label={btn.label} onClick={() => onSetView(btn.id)} />
                </div>
            )}
        </div>
    );
}

export default NavigationBar;