import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { useAuth } from 'contexts/auth';

const PurchaseOrderContext = React.createContext(null);

function PurchaseOrderProvider({type, children}) {
    const { tenant } = useSession();
    const { member } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [description, setDescription] = useState('');
    const [registrationPrice, setRegistrationPrice] = useState(0);
    const [registrations, setRegistrations] = useState([]);
    const [spaces, setSpaces] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [membership, setMembership] = useState(0);
    const [years, setYears] = useState(1);
    const [donation, setDonation] = useState(0);
    const [products, setProducts] = useState([]);
    const [taxes, setTaxes] = useState(0);
    const [canPurchase, setCanPurchase] = useState(false);
    const [registrationTotal, setRegistrationTotal] = useState(0);
    const [spacesTotal, setSpacesTotal] = useState(0);
    const [spacesDiscountTotal, setSpacesDiscountTotal] = useState(0);
    const [ticketTotal, setTicketTotal] = useState(0);
    const [productsTotal, setProductsTotal] = useState(0);
    const [shippingTotal, setShippingTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [agreement, setAgreement] = useState(false);
    const [paid, setPaid] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [serviceFee, setServiceFee] = useState(0);

    useEffect(() => {
        setRegistrationTotal((registrations.length * registrationPrice));
    }, [registrations])

    useEffect(() => {
        setSpacesTotal(spaces.reduce((a, c) => a + c.cost, 0));
        if (member) {
            setSpacesDiscountTotal(spaces.reduce((a, c) => a + c.memberDiscount, 0));
        }
    }, [spaces])

    useEffect(() => {
        setTicketTotal(tickets.reduce((a, c) => a + (parseInt(c.quantity) * c.ticket.price), 0));
    }, [tickets])

    useEffect(() => {
        setProductsTotal(products.reduce((a, c) => a + c.inventories[0].price, 0));
        setShippingTotal(products.reduce((a, c) => a + c.inventories[0].shipping, 0));
        setDiscountTotal(products.reduce((a, c) => a + c.inventories[0].discount, 0));
    }, [products])

    useEffect(() => {
        if (tenant && tenant.settings) {
            setServiceFee(tenant.settings.serviceFee);
        }
    }, [tenant])

    useEffect(() => {
        const totalBeforeFee = registrationTotal + (spacesTotal - spacesDiscountTotal) + ticketTotal + (membership * years) + donation + productsTotal + shippingTotal + taxes;
        if (totalBeforeFee > 0) {
            setGrandTotal((totalBeforeFee - discountTotal) + serviceFee)
        } else {
            setGrandTotal(0);
        }
    }, [registrationTotal, spacesTotal, ticketTotal, membership, years, donation, productsTotal, shippingTotal, discountTotal, taxes])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const jsonString = JSON.stringify({description, registrationPrice, registrations, tickets, selectedShifts, membership, years, donation, products, taxes, canPurchase, registrationTotal, ticketTotal, agreement, paid, completed});
            window.sessionStorage.setItem(type, jsonString);
        }
    }, [description, registrationPrice, registrations, tickets, selectedShifts, membership, years, donation, products, taxes, canPurchase, registrationTotal, ticketTotal, agreement, paid, completed])

    useEffect(() => {
        if (window.sessionStorage) {
            const jsonString = window.sessionStorage.getItem(type);
            if (jsonString) {
                try {
                    const poJson = JSON.parse(jsonString);
                    if (poJson.paid) {
                        setDescription(poJson.description);
                        setRegistrationPrice(poJson.registrationsPrice);
                        setRegistrations(poJson.registrations);
                        setTickets(poJson.tickets);
                        setSelectedShifts(poJson.selectedShifts);
                        setMembership(poJson.membership);
                        setYears(poJson.years);
                        setDonation(poJson.donation);
                        setProducts(poJson.products);
                        setTaxes(poJson.taxes);
                        setCanPurchase(poJson.canPurchase);
                        setRegistrationTotal(poJson.registrationTotal);
                        setTicketTotal(poJson.ticketTotal);
                        setAgreement(poJson.agreement);
                        setPaid(poJson.paid);
                        setCompleted(poJson.completed);
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(type);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(type);
        }
    }, [completed]);

    const resetPO = () => {
        window.sessionStorage.removeItem(type);
    }

    const provider = {
        description, setDescription,
        registrationPrice, setRegistrationPrice,
        discountTotal, setDiscountTotal,
        registrations, setRegistrations,
        spaces, setSpaces,
        tickets, setTickets,
        selectedShifts, setSelectedShifts,
        membership, setMembership,
        years, setYears,
        donation, setDonation,
        products, setProducts,
        taxes,
        serviceFee,
        canPurchase, setCanPurchase,
        registrationTotal, spacesTotal, spacesDiscountTotal, ticketTotal, grandTotal,
        productsTotal, shippingTotal, discountTotal,
        agreement, setAgreement,
        paid, setPaid,
        completed, setCompleted,
        resetPO
    };

    return <PurchaseOrderContext.Provider value={provider}>{children}</PurchaseOrderContext.Provider>
}

function usePurchaseOrder() {
    const context = React.useContext(PurchaseOrderContext);
    if (!context) {
        throw new Error('usePurchaseOrder must be used within a PurchaseOrderProvider');
    }
    return context;
}

export { PurchaseOrderProvider, usePurchaseOrder }