import React, { useState } from 'react';

const ConfirmationContext = React.createContext(null);

function ConfirmationProvider({children}) {
    const [confirmed, setConfirmed] = useState(false);

    const provider = {
        confirmed, setConfirmed
    }

    return <ConfirmationContext.Provider value={provider}>{children}</ConfirmationContext.Provider>
}

function useConfirmation() {
    const context = React.useContext(ConfirmationContext);
    if (!context) {
        throw new Error('useConfirmation must be used within a ConfirmationContext');
    }
    return context;
}

export { ConfirmationProvider, useConfirmation }