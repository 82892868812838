import { useLang } from 'contexts/lang';
import { useMemberLookup } from 'contexts/memberLookup';
import { useMemberTypes } from 'contexts/memberTypes';
import { useMembershipInfo } from 'contexts/purchasing/membershipInfo';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useSession } from 'contexts/session';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
function MemberLookup() {
    const { dict } = useLang();
    const { types } = useMemberTypes();
    const { tenant } = useSession();
    const [lookupEmail, setLookupEmail] = useState('');
    const { isFound, daysTillRenew, member, lookupMember, setCanRenew } = useMemberLookup();
    const {setFname, 
        setLname, 
        setPartner,
        setAddress1,
        setCity,
        setState,
        setZip,
        setCountry,
        setEmail} = usePersonalInfo();
    const { setMembership } = usePurchaseOrder();
    const { setType } = useMembershipInfo();
    const [ isChild, setIsChild ] = useState(false);
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (isFound) {
            setFname(member.fname);
            setLname(member.lname);
            setPartner(member.partner);
            setAddress1(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setCountry(member.country);
            setEmail(member.email_address);
            setType(member.type);
            const membershipType = types.find(t => t.id === member.type);
            setMembership(membershipType ? membershipType.price : 0);
        }
    }, [isFound])

    const handleLookup = () => {
        setStatus('');
        lookupMember(lookupEmail, (response) => {
            if (!response.success) {
                setStatus(response.errorMessage);
            } else {
                console.log(response.member);
                if (response.member && response.member.parentId && response.member.parentId > 0) {
                    setStatus('This is a child membership and cannot be used to renew a membership.');
                    setIsChild(true);
                    setCanRenew(false);
                } else {
                    setIsChild(false);
                    setCanRenew(true);
                }
            }
        });
    }

    const keyUpHandler = (e) => {
        if (e.key === "Enter") {
            handleLookup();
        }
    }

    return (
        <div className='personal-info'>
            <p>Enter in your email address to lookup your current membership.</p>
            <label>{dict.emailAddress}*</label>
            <input type='email' aria-required='true' value={lookupEmail} data-testid='email' maxLength='48' 
                onChange={(evt) => setLookupEmail(evt.target.value)} onKeyUp={keyUpHandler} />
            <div style={{marginTop: '5px'}}>
                <button onClick={handleLookup}>Lookup</button>
            </div>
            <div style={{marginTop:'10px'}}>Status: <span style={{color:'red'}}>{status}</span></div>
            {isFound && !isChild ?
                <div style={{marginLeft:'10px',fontWeight:'600'}}>
                    {`${member.fname} ${member.lname}`}<br/>
                    {member.address}<br/>
                    {`${member.city}, ${member.state} ${member.zip}`}<br/>
                    {`Membership type: ${types.find(t => t.id === member.type)?.label}`}<br/>
                    {`Membership expiration date: ${moment(member.expiration).format('dddd, MMMM DD yyy')}`}<br/>
                    {daysTillRenew > 0 ? `Days until you can renew: ${daysTillRenew}` : ''}
                </div>
                :
                <div style={{marginLeft:'10px',fontWeight:'600'}}>
                    Enter email address and click Lookup button
                </div>
            }
        </div>
    )
}

export default MemberLookup;