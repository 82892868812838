import React from 'react';
import { formatCurrency } from '_base';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';
import { useVendorInfo } from 'contexts/purchasing/vendorInfo';

function VendorSummary() {
    const {
        serviceFee, 
        taxes, 
        spaces, 
        spacesTotal, spacesDiscountTotal,
        tickets,
        ticketTotal,
        grandTotal,
        completed,
        resetPO,
    } = usePurchaseOrder();
    const { resetPi } = usePersonalInfo();
    const { resetRi } = useRegistrationInfo();
    const { resetVi } = useVendorInfo();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const cancelHandler = () => {
        resetPO();
        resetRi();
        resetVi();
        resetPi();
        if (pathname.indexOf('member') > -1) {
            navigate('/portal/member/events');
        } else {
            navigate('/portal/events');
        }
    }

    const finishHandler = () => {
        const redirectUrl = searchParams.get('redirect');
        if (redirectUrl) {
            window.location.href = redirectUrl
        } else {
            if (pathname.indexOf('member') > -1) {
                navigate('/portal/member/events');
            } else {
                navigate('/portal/events');
            }
        }
    }

    return (
        <div className='registration-summary'>
            <div className='registration-summary-title'>Order Summary</div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Spaces (x{spaces.length})</div>
                <div className='item-amount'>{formatCurrency(spacesTotal)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Tickets (x{tickets.reduce((a, c) => a + (parseInt(c.quantity)), 0)})</div>
                <div className='item-amount'>{formatCurrency(ticketTotal)}</div>
            </div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Member Discount</div>
                <div className='item-amount'>-{formatCurrency(spacesDiscountTotal)}</div>
            </div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Sub-total</div>
                <div className='item-amount'>{formatCurrency(spacesTotal)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Service fee</div>
                <div className='item-amount'>{formatCurrency(serviceFee)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Taxes</div>
                <div className='item-amount'>{formatCurrency(taxes)}</div>
            </div>
            <div className='registration-summary-total'>
                <div className='item-title'>Total</div>
                <div className='item-amount'>{formatCurrency(grandTotal)}</div>
            </div>
            <div className='registration-summary-buttons'>
                <button className='secondary' onClick={cancelHandler}>Cancel</button>
                <button disabled={!completed} onClick={finishHandler}>Finished</button>
            </div>
        </div>
    )
}

export default VendorSummary;